import axios from 'axios';
import Domain from 'config.js'
import { toast } from 'react-toastify';
export const GET_CATEGORIES = '[LANDING PAGE] GET_CATEGORIES';
export const ADMIN_CATEGORIES = '[ADMIN] ADMIN_CATEGORIES';
export const ADD_CATEGORIES = '[ADMIN] ADD_CATEGORIES';
export const EDIT_CATEGORIES = '[ADMIN] EDIT_CATEGORIES';

export function getCategories() {
    const request = axios.get(`${Domain}/buyer/getCategories`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_CATEGORIES,
                payload: response.data.categories
            })
        }
        )
    )
}

export function getAdminCategories() {
    const request = axios.get(`${Domain}/buyer/getCategories`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADMIN_CATEGORIES,
                payload: response.data.categories
            })
        }
        )
    )
}
export function adminAddCategory(name) {
    const request = axios.post(`${Domain}/admin/addCategory`, {name});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADD_CATEGORIES
            })
        }
        ).then(() => dispatch(getAdminCategories()))
        .then(() => toast.success('Category add successfully'))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot Add Category')
        })
    )
}
export function adminEditCategory(data) {
    const request = axios.put(`${Domain}/admin/editCategory`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: EDIT_CATEGORIES
            })
        }
        ).then(() => dispatch(getAdminCategories()))
        .then(() => toast.success('Category edit successfully'))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot edit Category')
        })
    )
}
