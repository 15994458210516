/**
 *  Report Page
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import CanvasJSReact from '../../assets/canvasjs.react';
import Loader from 'react-loader-spinner';
import * as Actions from 'store/actions'
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Reports() {
  const dispatch = useDispatch()
  const [orderCountDay, setOrderCountDay] = useState(null)
  const [orderCountWeek, setOrderCountWeek] = useState(null)
  const [orderCountMonth, setOrderCountMonth] = useState(null)
  const auth = useSelector(({auth}) => {
    return auth.login.success ? auth.user : false
  });
  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(Actions.loadingCountGraph(true))
    dispatch(Actions.orderCountGraph());
  },[dispatch])
  const orderCountGraphData = useSelector(({admin}) => admin.OrderCountGraph)
  const orderCountLoading = useSelector(({admin}) => admin.OrderCountGraph.Loading)
  useEffect(() => {
    if(orderCountGraphData){
      setOrderCountDay(orderCountGraphData.day)
      setOrderCountWeek(orderCountGraphData.week)
      setOrderCountMonth(orderCountGraphData.month)
    }
  },[orderCountGraphData])

    const DayOptions = {
      animationEnabled: true,
      title: {
        horizontalAlign: "left"
      },
      axisX: {
        title: "Date",        
        valueFormatString: "DD MMM",
      },
      axisY: {
        title: "Number Of Sales",
        includeZero: false
      },
      data: [{
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "#Sales",
        xValueFormatString: "DDD DD MMM",
        type: "spline",
        dataPoints: orderCountDay
      }]
    }

    const WeekOptions = {
      animationEnabled: true,
      title: {
        horizontalAlign: "left"
      },
      axisX: {
        title: "Date",        
        valueFormatString: "DD MMM",
      },
      axisY: {
        title: "Number Of Sales",
        includeZero: false
      },
      data: [{
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "$#,###",
        xValueFormatString: "DDD DD MMM",
        type: "spline",
        dataPoints: orderCountWeek
      }]
    }
    const MonthOptions = {
      animationEnabled: true,
      title: {
        horizontalAlign: "left"
      },
      axisX: {
        title: "Date",        
        valueFormatString: "DD MMM",
      },
      axisY: {
        title: "Number Of Sales",
        includeZero: false
      },
      data: [{
        lineColor: "#03d39f",
        markerColor: "#03d39f",
        yValueFormatString: "$#,###",
        xValueFormatString: "YYYY",
        type: "spline",
        dataPoints: orderCountMonth
      }]
    }

    return (
      <div className="section-ptb">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="d-sm-flex reports-tab w-100 mb-0">
                <h4>Reports</h4>
              </div>
            </Col>
          </Row>
          <div>
            {orderCountLoading ?
            <div className= "custom-loader">
              <Loader type="Puff" color="#04d39f" height={100} width={100} />
            </div>:
            <div>
               <Tabs>
                <TabList>
                  <Tab>Day</Tab>
                  <Tab>Week</Tab>
                  <Tab>Month</Tab>
                </TabList>
                <TabPanel>
                  <CanvasJSChart options={DayOptions} />
                </TabPanel>
                <TabPanel>
                  <CanvasJSChart options={WeekOptions} />
                </TabPanel>
                <TabPanel>
                  <CanvasJSChart options={MonthOptions} />
                </TabPanel>
              </Tabs>
            </div>}
          </div>
        </Container>
      </div>
    )
}
export default Reports;
