import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { toast } from 'react-toastify';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Button, FormGroup } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
import history from '@history'
import * as Actions from 'store/actions'
import cashOnDelImg from '../../assets/images/cashonDel.png'
import creditCardImg from '../../assets/images/credit-card.png'
import paypalImag from '../../assets/images/paypal.png'
import stripeImg from '../../assets/images/stripe.png'
import './detail.css'

function PaymentScreen(){
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
    const readFinalCart = () => {
        var cart = JSON.parse(localStorage.getItem("FinalCheckoutCartItems"));
        if (cart === null) {
            history.push(`/`)
        } 
        return cart
    }
    const totalAmount = () => {
        return JSON.parse(readFinalCart().totalPrice) + JSON.parse(readFinalCart().shippingCost)
    }
    const totalItems = () => {
        let totalItems = 0
        readFinalCart().products.map(itm => (
            totalItems+= itm.quantity
        ))
        return totalItems
    }
    const paypalSuccess = pay => {
        console.log(pay);
        if(pay.paid === true){
            const placeOrder = readFinalCart()
            placeOrder.paymentMethod = "Cash before delivery"
            dispatch(Actions.placeOrder(placeOrder))
        } else {
            toast.warn('Payment Error!')
        }
    }
    const placeOrderBasic = e => {
        e.preventDefault()
        const placeOrder = readFinalCart()
        placeOrder.paymentMethod = "Cash on delivery"
        dispatch(Actions.placeOrder(placeOrder))
    }
    const client = {
        sandbox:    'AQMQCZx6Cw6JWOvZSbYS4MyDJIx0fIeSkVfPjd-u_ILaklAXmE3p324NcPxe5t8sqElO-F4DaEiBSOqi',
        production: 'YOUR-PRODUCTION-APP-ID',
    }
    const onTokenCheckout = obj => {
        console.log(obj);
    }
    return(
        <div className="site-content" style={{background: '#fbfbfb'}}>
            <div className="inner-intro">
                <Container>
                    <Row className="intro-title align-items-center">
                        <Col md={6} className="text-left">
                            <div className="intro-title-inner">
                                <h1>Payment</h1>
                            </div>
                        </Col>
                        <Col md={6} className="text-right">
                            <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                                <li className="home">
                                    <span>
                                        <Link className="bread-link bread-home" to="/">Home</Link>
                                    </span>
                                </li>
                                <li><span>Payment</span></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="pt-5">
                <h3>Select Payment Method</h3>
            </Container>
            <div className='py-4'>
                <Container>
                    <Row>
                        <Col md={8}>
                        <Nav tabs style={{borderBottom: 'none'}}>
                            <NavItem style={{background: '#ededed'}}>
                            <NavLink style={{border: 'none'}}
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1'); }}>
                                    <div className='paymnet-tabs'>
                                        <img src={paypalImag} alt="paypalImag" />
                                        <span>Paypal</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem style={{background: '#ededed'}}>
                            <NavLink style={{border: 'none'}}
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}>
                                    <div className='paymnet-tabs'>
                                        <img src={cashOnDelImg} alt="cashOnDelImg" />
                                        <span>Cash on delivery</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem style={{background: '#ededed'}}>
                            <NavLink style={{border: 'none'}}
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => { toggle('3'); }}>
                                    <div className='paymnet-tabs'>
                                        <img src={creditCardImg} alt="creditCardImg" />
                                        <span>Credit/Debit Card</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem style={{background: '#ededed'}}>
                            <NavLink style={{border: 'none'}}
                                className={classnames({ active: activeTab === '4' })}
                                onClick={() => { toggle('4'); }}>
                                    <div className='paymnet-tabs'>
                                        <img src={stripeImg} alt="stripeImg" />
                                        <span>Stripe</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className='p-5' style={{background: '#fff'}}>
                                    <p className='mb-4'>Click the button below to pay with Paypal.</p>
                                    <PaypalExpressBtn client={client} currency={'USD'} shipping={1} total={totalAmount()} onError={e => toast.warn('Failed! Please try again.')} onSuccess= {e => paypalSuccess(e)}/>
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className='p-5' style={{background: '#fff'}}>
                                    <p className='mb-4'>You can pay in cash to our courier when you receive the goods at your doorstep</p>
                                    <Button className="btn btn-primary" onClick={e => placeOrderBasic(e)}>Confrim</Button>
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row className='p-5' style={{background: '#fff'}}>
                                    <Col md={8}>
                                    <form>
                                        <FormGroup>
                                            <label>Card Number *</label>
                                            <input type="text" className="form-control" name="number" required />
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Name on card *</label>
                                            <input type="text" className="form-control" name="name" required />
                                        </FormGroup>
                                        <Row form>
                                            <Col md={8}>
                                            <FormGroup>
                                                <label>Expiration date *</label>
                                                <input type="text" className="form-control" name="Expiration"required/>
                                            </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                            <FormGroup>
                                                <label>CVV *</label>
                                                <input type="text" className="form-control" name="cvv" required />
                                            </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button type="submit" className="btn btn-primary">Place Order</Button>
                                    </form>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="4">
                                <div className='p-5' style={{background: '#fff'}}>
                                    <p className='mb-4'>Click the button below to pay with Stripe.</p>
                                    <StripeCheckout token={onTokenCheckout} amount={totalAmount()*100} stripeKey="pk_test_InVvEaHftgc9lRtO55hoBlcp00hmG7bO7n"/>
                                </div>
                            </TabPane>
                        </TabContent>
                        </Col>
                        <Col md={4}>
                            <div className="sum-card2">
                                <h3>Order Summary</h3>
                                <p>total Items : {totalItems()}</p>
                                <p>total Amount : ${totalAmount()}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default PaymentScreen;