/**
 *  Shop Checkout Page
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col,Container, Input, CustomInput } from 'reactstrap';
import Loader from 'react-loader-spinner';
import * as Actions from 'store/actions'
import history from '@history';

function CheckOut(props) {
    const dispatch = useDispatch();
    const [fieldvalue, setFieldvalue] = useState(null)
    const [shippingCost, setShippingCost] = useState(null)
    const [shipCheck, setShipCheck] = useState(false)
    const [errors, setErrors] = useState({})
    const userData = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    const shipCost = useSelector(({app}) => app.shippingCost)
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(Actions.getShippingCost())
    },[dispatch])
    useEffect(() => {
        setShippingCost(shipCost)
    },[shipCost])
    useEffect(() => {
        setFieldvalue(userData.data)
    }, [userData])
    const onShipCheck = (e) => {
        setShipCheck(e.target.checked)
    }
    const ReadCartItems = () => {
        var cart = JSON.parse(localStorage.getItem("LocalCartItems"));
        if (cart == null) {
            props.history.push(`/`)
        } 
        return cart
    }
    if(fieldvalue === null || shippingCost === null){
        return <div className="custom-loader">
                    <Loader type="Puff" color="#04d39f" height={100} width={100} />
                </div>
    }
    const subtotal = () => {
        return parseFloat(ReadCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0)).toFixed(2)
    }
    const totalWithSipping = () => {
        return parseFloat(parseFloat(ReadCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0))+ parseFloat(shipCheck ? shippingCost.local : shippingCost.other)).toFixed(2)
    }
    const productIds = () => {
        return ReadCartItems().map(product => (
            {
                id: product.ProductID,
                ProductName: product.ProductName,
                ProductImage: product.ProductImage,
                quantity: product.Qty,
                rate : JSON.parse(product.Rate).toFixed(2),
            }
        ))
    }
    const onCheckOutSubmit = (e) => {
        e.preventDefault();
        if(handleValidation()){
            const placeOrder = {
                fullName : fieldvalue.fullName,
                phone : fieldvalue.phone,
                email : fieldvalue.email,
                totalPrice: subtotal(),
                shippingCost: parseFloat(shipCheck ? shippingCost.local : shippingCost.other),
                city: fieldvalue.city,
                deliveryAddress : fieldvalue.address,
                customer_id : userData.id,
                products: productIds()
            }
            console.log(placeOrder);
            localStorage.setItem("FinalCheckoutCartItems",JSON.stringify(placeOrder));
            history.push({pathname: '/payment'})
        }
      }

    const handleValidation = () => {
        let formIsValid = true;
        let errors = {}
        //First Name
        if (!fieldvalue["fullName"]) {
            formIsValid = false;
            errors["fullName"] = "Please Enter Your Name";
        }

            //streetno
        if (!fieldvalue["address"]) {
        formIsValid = false;
        errors["address"] = "Please Enter Street address";
        }

            //state
        if (!fieldvalue["city"]) {
        formIsValid = false;
        errors["city"] = "Please Enter Town / City";
        }

        if (!fieldvalue["phone"]) {
            formIsValid = false;
            errors["phone"] = "Please Enter Phone";
        }

        //Email ID
        if (!fieldvalue["email"]) {
        formIsValid = false;
        errors["email"] = "Please Enter Email ID";
        }
        setErrors( errors )
        return formIsValid;
    }

      const handleChange = (e) => {
        setFieldvalue({
            ...fieldvalue,
            [e.target.name] : e.target.value
        })
      }
    return (
            <>
            {userData && userData !==false ?
            <div>
            <div className="site-content">
                <div className="inner-intro">
                <Container>
                    <Row className="intro-title align-items-center">
                        <Col md={6} className="text-left">
                            <div className="intro-title-inner">
                                <h1>Checkout</h1>
                            </div>
                        </Col>
                        <Col md={6} className="text-right">
                            <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                                <li className="home">
                                    <span>
                                        <Link className="bread-link bread-home" to="/">Home</Link>
                                    </span>
                                </li>
                                <li><span>Checkout</span></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="content-wrapper mb-7">
                <Container>
                   <form onSubmit={onCheckOutSubmit}>
                        <Row className="mt-5">
                        <Col className="col-lg-6">
                            <Row>
                                <Col sm={12} >
                                <div className="billing-fields mt-5">
                                    <h3>Billing details</h3>
                                    <div className="billing-fields__field-wrapper">
                                        <div className="form-group">
                                            <label htmlFor="billing_first_name" className="">Full name&nbsp;<abbr className="required" title="required">*</abbr>
                                            </label>
                                           <Input type="text" className="form-control" name="fullName" id="billing_first_name" placeholder="" value={fieldvalue.fullName} onChange={e => handleChange(e)}/>
                                           <span className="error">{errors.fullName}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_address_1" className="">Street address&nbsp;<abbr className="required" title="required">*</abbr></label>
                                            <Input type="text" className="form-control" name="address" id="billing_address_1" placeholder="House number and street name" value={fieldvalue.address} onChange={e => handleChange(e)}/>
                                            <span className="error">{errors["address"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_city" className="">Town / City&nbsp;<abbr className="required" title="required">*</abbr></label>
                                            <Input type="text" className="form-control" name="city" id="billing_city" placeholder="" value={fieldvalue.city} onChange={e => handleChange(e)}/>
                                            <span className="error">{errors["city"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_phone" className="">Phone&nbsp;<abbr className="required" title="required">*</abbr></label>
                                           <Input type="tel" className="form-control" name="phone" id="billing_phone" placeholder="" value={fieldvalue.phone} onChange={e => handleChange(e)}/>
                                           <span className="error">{errors["phone"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="billing_email" className="">Email address&nbsp;<abbr className="required" title="required">*</abbr></label>
                                            <Input type="email" className="form-control" name="email" id="billing_email" placeholder="" value={fieldvalue.email} onChange={e => handleChange(e)}/>
                                            <span className="error">{errors["email"]}</span>
                                        </div>
                                        <div className="form-group">
                                            <CustomInput type="checkbox" id="exampleCustomInline2" label={`Ship within ${shippingCost.city}`} inline checked={shipCheck} onChange={e => onShipCheck(e)}/>
                                            <div className="privacy-policy-text">
                                                <p>* Note: Shipping cost in {shippingCost.city} is ${shippingCost.local.toFixed(2)} & out of city shipping cost is ${shippingCost.other.toFixed(2)}.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            </Row>
                        </Col>
                        <Col lg={6} className="mt-5">
                            <h3 id="order_review_heading">Your order</h3>
                            <div id="order_review" className="checkout-review-order">
                            {(ReadCartItems() !== null && ReadCartItems().length > 0) ?
                                <table className="shop_table checkout-review-order-table">
                                    <thead>
                                        <tr>
                                            <th className="product-name">Product</th>
                                            <th className="product-total">Total</th>
                                        </tr>
                                    </thead>
                                        <tbody>

                                        {ReadCartItems().map((CartItem, index) => (
                                                <tr className="cart_item" key={index}>
                                                <td className="product-name">
                                                    {CartItem.ProductName}&nbsp; <strong className="product-quantity">× {CartItem.Qty}</strong> </td>
                                                <td className="product-total">
                                                    <span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol">$</span>  {CartItem.Rate * CartItem.Qty} </span></span>
                                                </td>
                                                </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="cart-subtotal">
                                            <th>Subtotal</th>
                                        <td><span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol">$</span>{subtotal()}</span></span>
                                            </td>
                                        </tr>
                                        <tr className="shipping-totals shipping">
                                            <th>Shipping</th>
                                            <td><span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol">$</span>{shipCheck ? shippingCost.local : shippingCost.other}</span></span></td>
                                        </tr>
                                        <tr className="order-total">
                                            <th>Total</th>
                                            <td><strong><span className="woocs_special_price_code"><span className="Price-amount amount"><span className="Price-currencySymbol">$</span>{totalWithSipping()}</span></span></strong>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            :
                                <div>No Items found</div>
                            }
                                <div id="payment" className="checkout-payment">
                                    <div className="form-row place-order">
                                        <div className="terms-and-conditions-wrapper">
                                            <div className="privacy-policy-text">
                                                <p>Your personal data will be used to process your order, support your
                                                    experience throughout this website, and for other purposes described in
                                                    our
                                                </p>
                                            </div>
                                            <p className="form-row validate-required ml-5">
                                                <label className="form__label form__label-for-checkbox checkbox">
                                                    <Input checked disabled type="checkbox" className="form__input form__input-checkbox input-checkbox" name="terms" id="terms"/>
                                                    <span className="terms-and-conditions-checkbox-text">I have read
                                                        and agree to the website
                                                        </span>&nbsp;<span className="required">*</span>
                                                </label>
                                                <Input type="hidden" name="terms-field" value="1"/>
                                            </p>
                                        </div>

                                        <button type="submit" className="button alt" name="checkout_place_order" id="place_order" value="Place order" data-value="Place order">
                                         Proceed to Play
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    </form>
                </Container>
            </div>
        </div>
        </div> :
        <div className='mt-10'>
            <h2 className='text-center text-dark'>Please Login first to proceed with your order.</h2>
        </div>
        }
        </>

            )
}
export default CheckOut;
