/**
 *  Admin Site Product Add
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Actions from 'store/actions'
import { toast } from 'react-toastify';
import Spinner from 'reactstrap/lib/Spinner';
import ImageCrop from './ImageCrop'
import { v4 as uuidv4 } from 'uuid';
import './cropstyle.css'
import MEditor from './MEditor';

function Productadd(){
    const dispatch = useDispatch()
    const Category = useSelector(({admin}) => admin.Categories)
    const GLoading = useSelector(({admin}) => admin.GLoading)
    const [newForm, setNewForm] = useState(null)
    const [Cat, setCat] = useState(null)
    const [newPic, setNewPic] = useState([])
    const [editor, setEditor] = useState('')
    useEffect(() => {
        setCat(Category)
    },[Category])
    const formChange =(e) => {
        setNewForm({
            ...newForm,
            [e.target.name] : e.target.value
        })
    }
    const cropedFile = (childFile) => {
        let data = {id: uuidv4(),File: childFile}
        setNewPic([...newPic, data])
      }
    const delStateImg = res => {
        let selected = newPic.filter(prod => prod.id !== res)
        setNewPic(selected);
    }
    const onChangeEditor = ({html , text}) => {
        setEditor(html)
    }
    const formSubmit = (e) => {
        e.preventDefault()
        if(newPic.length < 2){
            return toast.warn('Please upload 2 or more pictures')
        }
        if (newPic.length > 5){
            return toast.warn('Please upload 4 or less pictures')
        }
        const fx = new FormData();
        fx.append('name', newForm.name)
        fx.append('brand', newForm.brand)
        fx.append('salePrice', newForm.salePrice)
        fx.append('disPrice', newForm.disPrice)
        fx.append('buyPrice', newForm.buyPrice)
        fx.append('category_id', newForm.category)
        fx.append('stock', newForm.stock)
        fx.append('SKU', newForm.SKU)
        fx.append('description', newForm.description)
        fx.append('details', editor)
        for(let i= 0; i< newPic.length; i++){
            fx.append('image', newPic[i].File, `proimage${i}`)
        }
        dispatch(Actions.adminGeneralLoading(true))
        dispatch(Actions.addNewProduct(fx))
    }
        return(
            <div className="site-content">
                <div className="content-wrapper section-ptb">
                    <Container>
                        <div className="product-content-top single-product single-product-edit">
                            <Row>
                                <div className="product-top-right col-xl-7 col-md-6">
                                    <div className="product-top-right-inner">
                                        <form onSubmit={formSubmit}>
                                                <FormGroup>
                                                    <Row form>
                                                        <Col md={6}>
                                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Product Name</Label>
                                                        <Input type="text" name = 'name' className="form-control" placeholder="Product Name" onChange= {e=> formChange(e)} required />
                                                        </Col>
                                                        <Col md={6}>
                                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Brand</Label>
                                                        <Input type="text" name = 'brand' className="form-control" placeholder="Brand" onChange= {e=> formChange(e)} required />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Row form>
                                                        <Col md={6}>
                                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Sale Price</Label>
                                                        <Input type="text" name = 'salePrice' className="form-control" placeholder="Sale Price" onChange= {e=> formChange(e)} required />
                                                        </Col>
                                                        <Col md={6}>
                                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Discounted Price</Label>
                                                        <Input type="text" name = 'disPrice' className="form-control" placeholder="Discounted Price" onChange= {e=> formChange(e)} required />
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Row form>
                                                        <Col md={6}>
                                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Buy Price</Label>
                                                        <Input type="text" name = 'buyPrice' className="form-control" placeholder="Buy Price" onChange= {e=> formChange(e)} required />
                                                        </Col>
                                                        <Col md={6}>
                                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Category</Label>
                                                        <select name = 'category' className="form-control"  onChange= {e=> formChange(e)} required>
                                                            <option value="">Select Category</option>
                                                            {Cat && Cat!==null ?
                                                            Cat.map(cat => (
                                                            <option value={cat.id} key={cat.id}>{cat.name}</option>
                                                            )) : <option value="cat.id">wait</option>}
                                                        </select>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Row form>
                                                        <Col md={6}>
                                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Product Stock</Label>
                                                        <input type="text" name = 'stock' className="form-control" placeholder="Product Stock" onChange= {e=> formChange(e)} required ></input>
                                                        </Col>
                                                        <Col md={6}>
                                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Product SKU</Label>
                                                        <input type="text" name = 'SKU' className="form-control" placeholder="Product SKU" onChange= {e=> formChange(e)} required ></input>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="pb-2 mb-0 text-dark font-weight-bold">Short Description</Label>
                                                    <Input  type="textarea" name = 'description' className="form-control" rows="2" placeholder="Product Description" onChange= {e=> formChange(e)} />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="pb-2 mb-0 text-dark font-weight-bold">Details & Specification</Label>
                                                    {/* <Input  type="textarea" name = 'details' className="form-control" rows="2" placeholder="Details & Specification" onChange= {e=> formChange(e)} /> */}
                                                    <MEditor onChangeEditor={onChangeEditor}/>
                                                </FormGroup>
                                                <p>Note: Please upload high quality 2-4 pictures of your product.</p>
                                                <ImageCrop cropedFile={cropedFile} />
                                                {GLoading ? 
                                                    <button type ='submit' href="#" className="btn btn-primary mb-2 mr-2" disabled style={{padding: '6px 24px'}}><Spinner /></button>:
                                                    <button type ='submit' href="#" className="btn btn-primary mb-2 mr-2"> Save </button>
                                                }
                                                <Link to="/admin/Product" className="btn btn-danger mb-2"> Cancel </Link>
                                        </form>
                                    </div>
                                </div>
                                <div className="product-top-left col-xl-5 col-md-6">
                                    <div className='text-center mt-4'>
                                        <Row className= 'mt-4'>
                                        {newPic && newPic.length > 0 ?
                                        newPic.map(img => (
                                            <Col md={6} sm={6} xs={6} key={img.id} className='pb-4'>
                                                <div className='imgcrop-div'>
                                                <img src={URL.createObjectURL(img.File)} alt='crop-e' width='100%'/>
                                                <span className='del-mark' onClick={e => delStateImg(img.id)}>X</span>
                                                </div>
                                            </Col>
                                        )) : null
                                        }
                                        </Row>
                                    </div>
                                </div>
                            </Row>
                            </div>
                    </Container>
                </div>
            </div>
        )
}
export default Productadd;
