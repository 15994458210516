import * as Actions from '../actions';
import * as moment from 'moment';

const initialState = {
    Loading : false,
    Categories : null,
    Products : {
        rows : null,
        totalProducts : null
    },
    productDetail: null,
    productComments:null,
    shippingCost: null,
    Orders : {
        rows : null,
        totalOrders : null,
        orderDetails : null
    },
};

const appReducer = function (state = initialState, action){
    switch ( action.type ){
        case Actions.GET_CATEGORIES:
            {
                let resFormattedMapped = []
                if (action.payload) {
                    let res = action.payload
                    resFormattedMapped = res.map(cat => (
                        {
                            id: cat.id,
                            name: cat.name,
                            created_at: moment(cat.datetime).format('MMM DD YYYY h:mm A')
                        }
                    ))
                }
                return {
                    ...state,
                    Categories: resFormattedMapped,
                };
            }
        case Actions.GET_PRODUCTS:
            {
                let resFormattedMapped = []
                let totalProducts
                if (action.payload) {
                    let res = action.payload
                    totalProducts = res.count
                    resFormattedMapped = res.rows.map(inv => (
                        {
                            id: inv.id,
                            name: inv.name,
                            salePrice: inv.salePrice,
                            disPrice: inv.disPrice,
                            rating: inv.ratings.toFixed(1),
                            category : inv.productCategory.name,
                            pictures: inv.productimages.map(pix => (
                                pix.image
                            )),
                        }
                    ))
                }
                return {
                    ...state,
                    Products: {rows: resFormattedMapped, totalProducts},
                };
            }
        case Actions.GET_PRODUCTDETAIL:
            {
                let resFormattedMapped = {}
                if (action.payload) {
                    let product = action.payload
                    resFormattedMapped = {
                            id: product.id,
                            name: product.name,
                            pictures: product.productimages.map(pix => (
                                pix.image
                            )),
                            stock: product.stock,
                            salePrice: product.salePrice,
                            disPrice: product.disPrice,
                            brand : product.brand,
                            description: product.description,
                            details: product.details,
                            rating: product.ratings,
                            category: product.productCategory.name,
                            createdAt: moment(product.createdAt).format('MMM DD YYYY h:mm A'),
                        }
                }
                return {
                    ...state,
                    productDetail: resFormattedMapped,
                };
            }
        case Actions.GET_PRODUCTCOMMENTS:
            {
                let resFormattedMapped = []
                if (action.payload) {
                    let res = action.payload
                    resFormattedMapped = res.map(comment => (
                        {
                            id: comment.id,
                            name: comment.name,
                            comment: comment.comment,
                            rating: comment.rating,
                            createdAt: moment(comment.createdAt).format('MMM DD YYYY h:mm A')
                        }
                    ))
                }
                return {
                    ...state,
                    productComments: resFormattedMapped,
                };
            }
        case Actions.GET_SHIPPINGCOST:
            {
                let shippingCost = {}
                if(action.payload){
                    shippingCost = action.payload
                }
                return {
                    ...state,
                    shippingCost
                }
            }
        case Actions.GET_CUSTOMER_ORDERS:
            {
                let totalOrders
                let rows = []
                if(action.payload){
                    let res = action.payload
                    totalOrders = res.count
                    console.log(res.rows);
                    rows = res.rows.map(idx => (
                        {
                            id: idx.id,
                            fullName: idx.fullName,
                            phone: idx.phone,
                            email: idx.email,
                            totalPrice: idx.totalPrice,
                            shippingCost: idx.shippingCost,
                            deliveryAddress: idx.deliveryAddress,
                            city: idx.city,
                            paymentMethod: idx.paymentMethod,
                            orderStatus: idx.orderStatus,
                            productOrder : idx.productOrder.map(pdx => ({
                                id : pdx.id,
                                product_id : pdx.productorderProduct.id,
                                name : pdx.productorderProduct.name,
                                quantity : pdx.quantity,
                                rate : pdx.rate,
                                rating_id : pdx.rating_id,
                                productImage: pdx.productorderProduct.productimages[0].image
                            })),
                            createdAt: moment(idx.createdAt).format('MMM DD YYYY h:mm A')
                        }
                    ))
                }
                return {
                    ...state,
                    Orders: {totalOrders, rows}
                }
            }
        case Actions.SET_LOADING:
            {
                return {
                    ...state,
                    Loading: !state.Loading
                }
            }
        default:
            {
                return state;
            }
    }
}

export default appReducer;