/**
 *  Admin Invoive Page
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container,Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { Link } from 'react-router-dom';
import * as Actions from 'store/actions'
import Loader from 'react-loader-spinner';
import Domain from 'config.js'
import Spinner from 'reactstrap/lib/Spinner';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';

function AdminInvoices(props) {
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [statusModal, setStatusModal] = useState(false)
    const [statusId, setStatusId] = useState('')
    const [page, setPage] = useState(0)
    const [searchProduct, setSearchProduct] = useState('')
    const [invoiceview, setInvoiceview] = useState('')
    const [invoices, setInvoices] = useState([{id: 99, fullName: 'Dummy'}])
    const [totalInvoices, setTotalInvoices] = useState(null)
    const auth = useSelector(({auth}) => {
      return auth.login.success ? auth.user : false
  });
  const invData = useSelector(({admin}) => admin.Orders)
  const Loading = useSelector(({admin}) => admin.Orders.Loading)
  useEffect(() => {
    window.scrollTo(0, 0)
    if(auth && auth.role === "admin"){
        dispatch(Actions.adminOrderLoading(true))
        dispatch(Actions.getOrdersAdmin(page))
    }
    },[page])
  useEffect(() => {
    if(invData){
      setInvoices(invData.rows)
      setTotalInvoices(invData.totalOrders)
    }
    },[invData])
    const toggle = () => {
      setModal(!modal)
    }
    const onSearchProduct = (searchText) => {
      console.log(searchText)
    }

    const onViewInvoicePopup = (data) => {
      setInvoiceview(data)
      toggle();
    }
    const viewInvoice = invoiceview;
    const onUpdateStatus = (id) => {
      setStatusId(id)
      statusToggle()
    }
    const handlePageChange = (pg) => {
        setPage(pg)
    }
    const statusToggle = () => {
      setStatusModal(!statusModal)
    }
    const onSumitStatus = (e) => {
      e.preventDefault()
      let data = {
        id: statusId,
        orderStatus : e.target.status.value ? e.target.status.value : null,
        page
      }
      dispatch(Actions.adminUpdateOrder(data))
      statusToggle()
    }
    const columns = [
        {
            maxWidth: 75,
            Header: 'No.',
            accessor: 'id',
        },
        {
            Header: 'Full Name',
            accessor: 'fullName',
        },
        {
            Header: 'Phone',
            accessor: 'phone',
        },
        {
            Header: 'Total Price',
            accessor: 'totalPrice',
        },
        {
            Header: 'Order Status',
            accessor: 'orderStatus',
        },
        {
            Header: 'Delivery Address',
            accessor: 'deliveryAddress',
        },
        {
            Header: 'City',
            accessor: 'city',
        },
        {
            Header: 'Payment Method',
            accessor: 'paymentMethod',
        },
        {
            Header: 'View',
            accessor: 'view',
            maxWidth: 80,
            style : {
              textAlign: "right"
            },
            Cell: props => {
                return (
                <div>
                    <Link to='#' className="view-button" onClick={() => onViewInvoicePopup(props.original)}>View<i className="fa fa-eye pl-2"></i></Link>
                 </div>
                 )
                },
        },
        {
            Header: 'Action',
            accessor: 'action',
            maxWidth: 80,
            Cell: props => {
                return (
                <div>
                    <Link to='#' className="view-button" onClick={() => onUpdateStatus(props.original.id)}>View<i className="fa fa-cog pl-2"></i></Link>
                 </div>
                 )
                },
        }
     ]
     if(invoices === null){
      return (
          <div id="preloader">
              <Loader type="Puff" color="#04d39f" height={100} width={100} />
          </div>
      )
  }

    return (
      <div className="section-ptb">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="mb-0">
                <h4>Invoice List</h4>
              </div>
              <div className="mb-4">
                <form>
                  <div className="form-group">
                    <input type="text" className="form-control"  placeholder="Search Invoice" value={searchProduct} onChange={(e) => onSearchProduct(e.target.value)}></input>
                  </div>
                </form>
              </div>
              <ReactTable className="invoices-table"
                  loading={Loading}
                  loadingText={<Spinner style={{color: '#04d39f'}}/>}
                  data={invoices}
                  columns={columns}
                  onPageChange={(pg) => handlePageChange(pg)}
                  page={page}
                  manual
                  pages={Math.ceil(totalInvoices/20)}
                  showPageSizeOptions={false}
                  showPageJump={false}
                  minRows={1}
                  />
                  {/* modal-view */}
              <Modal isOpen={modal} toggle={toggle} className="modal-view modal-lg modal-dialog-centered">
                <ModalHeader toggle={toggle}></ModalHeader>
                {viewInvoice !== null ?
                  <ModalBody>
                    <div className="success-screen">
                      <div className="delivery p-4 p-md-5 text-center" style={{background: '#04d39f'}}>
                        <span className="h5 text-white">Invoice: #pk-{viewInvoice.id}</span>
                        <h2 className="mb-0 mt-2 text-white">{viewInvoice.createdAt}</h2>
                      </div>
                      <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3">
                        <Row>
                          <Col lg={6}>
                          <h6>Ship To</h6>
                            <ul className="list-unstyled mb-0">
                              <li>{viewInvoice.fullName}</li>
                              <li>{viewInvoice.phone}</li>
                              <li>{viewInvoice.email}</li>
                              <li>{viewInvoice.deliveryAddress}</li>
                            </ul>
                          </Col>
                          <Col lg={6} className="text-lg-right mt-4 mt-lg-0">
                            <h6>Summary</h6>
                            <ul className="list-unstyled mb-0">
                              <li><span>Order ID:</span> <strong>{viewInvoice.id}</strong></li>
                              <li><span>Order Date:</span> <strong>{viewInvoice.createdAt}</strong></li>
                              <li><span>Order Total:</span> <strong>${viewInvoice.totalPrice}</strong></li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                      <div className="ordered-detail">
                        <h5 className="mb-4">Your Ordered Details</h5>
                        <div className="table-responsive">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                            <th scope="col">Picture</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Price</th>
                            <th scope="col">Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                          {viewInvoice && viewInvoice !== null ?
                          viewInvoice.productOrder.map(inv => (
                            <tr className="ordered-item" key= {inv.product_id}>
                            <td  className="ordered-name">
                              <span><img src={`${Domain}${inv.productImage}`}  className="img-fluid" width='80px'/></span>
                            </td>
                            <td  className="ordered-name">
                              <span>{inv.name}</span>
                            </td>
                            <td className="ordered-quantity">
                              <span>{inv.quantity}</span>
                            </td>
                            <td className="ordered-price">
                            <span>${inv.rate}</span>
                            </td>
                            <td className="ordered-price">
                            <span>${inv.rate * inv.quantity}</span>
                            </td>
                          </tr> 
                          )) : <tr>null</tr>
                        }
                            
                          </tbody>
                        </table>
                        </div>
                        <div className="table-responsive">
                        <table className="table total-table table-borderless mt-4 mb-0">
                          <tbody>
                            <tr>
                              <td>Subtotal</td>
                              <td className="text-right">${viewInvoice.totalPrice}</td>
                            </tr>
                            <tr>
                            <td>Shipping</td>
                            <td className="text-right">${viewInvoice.shippingCost}</td>
                            </tr>
                            <tr className="border-top">
                            <td><strong className="h5">Total</strong></td>
                              <td className="text-right h5"><strong>${parseFloat(viewInvoice.totalPrice) + parseFloat(viewInvoice.shippingCost)}</strong></td>
                            </tr>
                          </tbody>
                        </table>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                  :
                    null
                  }
              </Modal>
              <Modal isOpen={statusModal} toggle={statusToggle} className="modal-view modal-lg modal-dialog-centered">
                <ModalHeader toggle={statusToggle}></ModalHeader>
                <ModalBody>
                  <div className="success-screen">
                      <div className="delivery p-4 p-md-5 text-center" style={{background: '#04d39f'}}>
                        <span className="h5 text-white">Invoice: #pk-{statusId}</span>
                      </div>
                  </div>
                  <div className="pt-md-3 px-md-3 pb-3">
                    <Form onSubmit={e => onSumitStatus(e)}>
                      <FormGroup>
                        <Label for="exampleSelect" md={6}>Select Status</Label>
                        <Col md={6}>
                          <Input type="select" name="status" id="exampleSelect">
                            <option value='Pending'>Pending</option>
                            <option value='Delivered'>Delivered</option>
                            <option value='Canceled'>Canceled</option>
                            <option value='Returned'>Returned</option>
                          </Input>
                        </Col>
                      </FormGroup>
                      <div className='px-md-3'>
                        <Button type='submit' color='primary'>Save</Button>
                        <Button color='secondary' className='ml-2' onClick={e => statusToggle()}>Cancel</Button>
                      </div>
                    </Form>
                  </div>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
          )
}
export default AdminInvoices;
