import React from 'react';
import { Button, Input, Form, Spinner } from 'reactstrap';
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'store/actions'
import { toast } from 'react-toastify';
import History from '@history';
import './pagesStyle.css'


function ChangePassword(props){
    const dispatch = useDispatch()
    const login = useSelector(({auth}) => {
        return auth.login.success ? true : false
    })
    const wait = useSelector(({app}) => app.Loading)
    if(login === true){
        History.push({
            pathname: '/'
        })
    }
    let query = queryString.parse(props.location.search)
    console.log(query);
    const updatePassword = e => {
        e.preventDefault()
        if(e.target.password.value.length < 6){
            return toast.warn("Password too short")
        }
        if(e.target.password.value !== e.target.password2.value){
            return toast.warn('Password do not match')
        }
        let data = {
            email : query.e,
            resetToken: query.emToken,
            password: e.target.password.value,
            password2: e.target.password2.value
        }
        console.log(data);
        dispatch(Actions.loadingToggle())
        dispatch(Actions.updatepassword(data))
    }
    return(
        <div>
        <div className='reset-cont1st' style={{display: 'flex', height: '100vh'}}>
            <div className="reset-container">
                <div className="reset-1st">
                    <div className="reset-main">
                        <div className="reset-div">
                            <div className="img-div">
                                <i class="fa fa-unlock-alt" aria-hidden="true" style={{fontSize: '80px'}}></i>
                            </div>
                            <h6 className='reset-line'>Change YOUR PASSWORD</h6>
                            <Form onSubmit={e => updatePassword(e)} className='reset-form'>
                                <div className="reset-input-div">
                                    <div className="reset-input-2">
                                        <Input type="password" name="password" placeholder="Password *" className='reset-input' required/>
                                    </div>
                                    <div className="reset-input-2 mt-4">
                                        <Input type="password" name="password2" placeholder="Retyoe password *" className='reset-input' required/>
                                    </div>
                                </div>
                                {wait ? <Button className='reset-btn mt-2' disabled><Spinner style={{margin: 'auto', color: '#fff'}} /></Button>:
                                <Button color='primary' type='submit' className='reset-btn'>Update</Button>}
                            </Form>
                            <div className='mt-4'><a href="/">Go back to login</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default ChangePassword;