import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

class ImageCrop extends PureComponent {
  state = {
    crop: {
      unit: '%',
      width: 100,
      aspect: 1,
    },
    modal: false,
    src: null
  };
  toggle = () =>{
    this.setState({
      modal: !this.state.modal,
      src: null
    })
  }
  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
          src: reader.result
        })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'aunox.jpeg'
      );
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        var fileImg = new File([blob], fileName, {type: 'image/jpeg', lastModified: Date.now()});
        resolve(fileImg);
      }, 'image/jpeg');
    });
  }

  render() {
    const { crop, croppedImageUrl, src, modal } = this.state;
    return (
      <div>
        <Link to="#" className="btn btn-primary mb-2" onClick={this.toggle}>Upload photo</Link>
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle} style={{borderBottom: '1px solid #dee2e6'}}><div className="p-3">Upload Photo</div></ModalHeader>
          <ModalBody>
            <div className='text-center mb-3'>
              <input type="file" accept="image/*" onChange={this.onSelectFile} />
            </div>
            <div style = {{ width : '90%', margin: '0 auto'}} className='text-center'>
              {src && src !== null ?
                <ReactCrop
                  src={src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />:
                <img src={require(`../../../assets/images/upload-placeholder.jpg`)} alt="placeholder"/>
              }
            </div>
          </ModalBody>
          <ModalFooter>
          {croppedImageUrl && (
              <Button color="primary" onClick={e => {
                this.props.cropedFile(croppedImageUrl);
                this.toggle()
              }}>Crop</Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default ImageCrop