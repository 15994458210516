
import React, { Fragment, useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as AppActions from 'store/actions'
import history from '@history'
import { Row, Col, Container, Modal, ModalHeader, ModalBody, DropdownMenu, 
    DropdownItem, Navbar, NavbarToggler, Nav, NavItem, NavLink, UncontrolledDropdown, 
    Collapse, TabContent, TabPane, Button, Spinner } from 'reactstrap';
import classnames from 'classnames';
import * as Actions from 'auth/store/actions'
import Domain from 'config.js'
import { toast } from 'react-toastify';

function Header () {
    const dispatch = useDispatch();
    const login = useSelector(({ auth }) => {
        return auth.login.success ? auth.user : false
    });
    const categoryData = useSelector(({app}) => app.Categories)
    const modalTogger = useSelector(({auth}) => auth.login.modalToggle)
    const wait = useSelector(({app}) => app.Loading)
    const [modal, setModal] = useState(false)
    const [activeTab, setActiveTab] = useState("1")
    const [isOpen, setIsOpen] = useState(false)
    const [collapsed, setCollapsed] = useState(true)
    const [cartHide, setCartHide] = useState(true)
    const [classset, setClassset] = useState('')
    const [categories, setCategories] = useState(null)
    useEffect(() => {
        dispatch(AppActions.getCategories());
    },[dispatch])
    useEffect(() => {
        setCategories(categoryData)
      },[categoryData])

    useEffect(() => {
        if (login) {
            setModal(false);
            setIsOpen(false)
        }
    }, [login]);
    useEffect(() => {
        setModal(modalTogger);
    }, [modalTogger]);
    useEffect(() => {
        if (login && login.role === 'admin') {
            history.push({
                pathname: '/admin/dashboard'
            })
        }
    }, [login]);

    const toggle = () => {
        setModal(!modal);
        setIsOpen(!isOpen)
    }
    const myToggler = () => {
        dispatch(Actions.modelToggle())
    }

    const toggleNavbar = () => {
        setCollapsed(!collapsed)
      }

    const logintoggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }}

    const ReadCartItems = () => {
        return JSON.parse(localStorage.getItem("LocalCartItems"));
    }
    const removeFromCart = (Index) => {
        var UpdatedCart = JSON.parse(localStorage.getItem("LocalCartItems"));
        UpdatedCart = UpdatedCart.slice(0, Index).concat(UpdatedCart.slice(Index + 1, UpdatedCart.length));
        localStorage.removeItem("LocalCartItems");
        localStorage.setItem("LocalCartItems", JSON.stringify(UpdatedCart));
    }

    const ShowCart = () => {
        if(cartHide === true){
        var elm = document.getElementById("DivCartContent");
        if(elm !== null){
        document.getElementById("DivCartContent").setAttribute("style","display:block");
        setCartHide(false)
        }}
    }

      const HideCart = () =>{
           var elm = document.getElementById("DivCartContent");
           if(elm != null){
           document.getElementById("DivCartContent").setAttribute("style","display:none");
           setCartHide(true)
           }
      }

      const closeNavbar = () => {
            if (collapsed !== true) {
            toggleNavbar();
            }
        }
    const onClickClassAdd = (pages) => {
        if(classset !== pages){
            setClassset(pages)
        } else{
            if(Object.keys(classset).length === 0){
                setClassset(pages)
            } else{ setClassset('') }
        }}

    const OpenSubmenuOpen = (id) => {
        var elm = document.getElementById(id);
        if(elm != null){
            document.getElementById(id).setAttribute("class","dropdown-menu dropdown-menu-right show")
        }}

    const OpenSubmenuClose = (id) => {
        var elm = document.getElementById(id);
        if(elm !== null){
            document.getElementById(id).setAttribute("class","dropdown-menu dropdown-menu-right")
        }}

    const loginSubmit = (e) => {
        e.preventDefault()
        const loginData = {
            email : e.target.email.value,
            password: e.target.password.value
        }
        dispatch(Actions.submitLogin(loginData))
    }
    const onSignup = (e) => {
        e.preventDefault()
        let password = e.target.password.value;
        let password2 = e.target.password2.value;
        if(password !== password2){
            toast.warn("Passwords do not match");
            return;
        }
        if(password.length < 6){
            toast.warn("Passwords too short");
            return;
        }
        let loginData = {
            fullName : e.target.fullName.value,
            email : e.target.email.value,
            password,
            password2
        }
        dispatch(AppActions.loadingToggle())
        dispatch(AppActions.signupCustomer(loginData))
    }
    const logoutUser = () => {
        dispatch(Actions.logoutUser())
    }
        let pathnames = document.location.href;
        let pathArray = pathnames.split('/');
        let pageName = '/'+pathArray[pathArray.length -1];
    return (
        <header className="site-header header-style-menu-center" id="site-header">
                    <div>
                        <div className="topbar topbar-bg-color-default topbar-desktop-on topbar-mobile-off">
                            <div className="container-fluid">
                                <Row>
                                    <Col lg={6} sm={12}>
                                        <div className="topbar-left text-left">
                                            <div className="topbar-link">
                                                <ul>
                                                    <li className="topbar_item topbar_item_type-email">
                                                        <Link to="/Contactus"><i className="fa fa-envelope-o">&nbsp;</i>contact@infinitybits.pk</Link>
                                                    </li>
                                                    <li className="topbar_item topbar_item_type-phone_number">
                                                        <Link to="/Contactus"><i className="fa fa-phone">&nbsp;</i>+92-332-5409-980</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} sm={12}>
                                        <div className="topbar-right text-right">
                                            <div className="topbar-link">
                                                <ul>
                                                    <li className="topbar_item topbar_item_type-topbar_menu">
                                                        <div className="menu-top-bar-menu-container">
                                                            <ul className="top-menu list-inline">
                                                                {!login ? (
                                                                    <li>
                                                                        <Link to="#" onClick={myToggler} data-toggle="modal" data-target="#"><i className="fa fa-sign-in">&nbsp;</i>Login</Link>
                                                                    </li>
                                                                ) : (
                                                                    <>
                                                                    <li className="menu-item">
                                                                        <Link to="/Account/AccountProfile">My account</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="#" onClick={logoutUser}><i className="fa fa-sign-out">&nbsp;</i>Logout</Link>
                                                                    </li>
                                                                    </>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                    <li className="topbar_item topbar_item_type-social_profiles">
                                                        <div className="topbar-social_profiles-wrapper">
                                                            <ul className="topbar-social_profiles">
                                                                <li className="topbar-social_profile">
                                                                    <a href={'https://www.facebook.com'} target="_blank" rel="noopener noreferrer">
                                                                        <i className="fa fa-facebook" />
                                                                    </a>
                                                                </li>
                                                                <li className="topbar-social_profile" >
                                                                    <a href={'https://twitter.com/'} target="_blank" rel="noopener noreferrer">
                                                                        <i className="fa fa-twitter" />
                                                                    </a>
                                                                </li>
                                                                <li className="topbar-social_profile" >
                                                                    <a href={'https://plus.google.com/'} target="_blank" rel="noopener noreferrer">
                                                                        <i className="fa fa-google-plus" />
                                                                    </a>
                                                                </li>
                                                                <li className="topbar-social_profile" >
                                                                    <a href={'https://vimeo.com/'} target="_blank" rel="noopener noreferrer">
                                                                        <i className="fa fa-vimeo" />
                                                                    </a>
                                                                </li>
                                                                <li className="topbar-social_profile" >
                                                                    <a href={'https://in.pinterest.com/'} target="_blank" rel="noopener noreferrer">
                                                                        <i className="fa fa-pinterest" />
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="header-main header-main-bg-color-default">
                            <div className="container-fluid">
                                <Row>
                                    <Col lg={12}>
                                        <div className="row align-items-center justify-content-md-center">
                                            <Col xl={2} lg={2} className="col-6">
                                                <div className="logo-wrapper">
                                                    <Link to="/">
                                                        <img src={require('assets/images/main-logo.png')} alt="main-logo2"/>
                                                    </Link>
                                                </div>
                                                <div className="clearfix" />
                                            </Col>
                                            <div className="col" id="mainMenu">
                                                <div className="header-nav header-nav-bg-color-default">
                                                    <div className="header-nav-wrapper">
                                                        <Container>
                                                            <Row>
                                                                <div className="col-12">
                                                                    <div className="primary-nav">
                                                                        <div className="primary-nav-wrapper">
                                                                            <nav className="mega-menu">
                                                                                <div className="menu-list-items">
                                                                                     <Navbar light expand="md" className="front_menu">
                                                                                        <NavbarToggler onClick={toggle} />
                                                                                        <Collapse isOpen={isOpen} navbar>
                                                                                            <Nav className="ml-auto" navbar>
                                                                                                <Fragment>
                                                                                                    <NavItem>
                                                                                                        <NavLink tag={Link} to='/'>home</NavLink>
                                                                                                    </NavItem>
                                                                                                </Fragment>
                                                                                            </Nav>
                                                                                            <Nav className="ml-auto" navbar>
                                                                                                <Fragment>
                                                                                                    <UncontrolledDropdown nav inNavbar onMouseEnter={()=>OpenSubmenuOpen(`submenu_0`)} onMouseLeave={()=>OpenSubmenuClose(`submenu_0`)}>
                                                                                                    <Link aria-haspopup="true" to='#' className="dropdown-toggle nav-link" aria-expanded="true">Shop</Link>
                                                                                                    <DropdownMenu right id={`submenu_0`}>
                                                                                                        {categories && categories.map((subNavLink, index) => (
                                                                                                      <DropdownItem key={index} tag={Link} to={`/shop?cat_id=${subNavLink.id}`}>{subNavLink.name}</DropdownItem>
                                                                                                        ))}
                                                                                                    </DropdownMenu>
                                                                                                </UncontrolledDropdown>
                                                                                                </Fragment>
                                                                                            </Nav>
                                                                                            <Nav className="ml-auto" navbar>
                                                                                            <Fragment>
                                                                                                    <NavItem>
                                                                                                        <NavLink tag={Link} to='/Contactus'>Contact Us</NavLink>
                                                                                                    </NavItem>
                                                                                                </Fragment>
                                                                                            </Nav>
                                                                                        </Collapse>
                                                                                    </Navbar>
                                                                                </div>
                                                                            </nav>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </Container>
                                                    </div>
                                                </div>
                                            </div>
                                            <Col xl={2} lg={2} className="col-6">
                                                <div className="header-nav-right-wrapper">
                                                    <div className="ciya-tools">
                                                        <div className="ciya-tools-wrapper">
                                                            <ul className="ciya-tools-actions">
                                                                <li className="ciya-tools-action ciya-tools-cart">
                                                                    {
                                                                        (ReadCartItems() === null || ReadCartItems().length === 0) ?
                                                                        <Link className="cart-link" to="#" onClick={() => ShowCart()} >
                                                                            <span className="cart-icon"><i className="glyph-icon pgsicon-ecommerce-empty-shopping-cart" /></span>
                                                                            <span className="cart-count count">  {ReadCartItems() === null ? 0 : ReadCartItems().length}  </span>
                                                                        </Link>
                                                                        :
                                                                        <Link className="cart-link" to="/ShopingCart" onClick={() => ShowCart()} >
                                                                            <span className="cart-icon"><i className="glyph-icon pgsicon-ecommerce-empty-shopping-cart" /></span>
                                                                            <span className="cart-count count">  {ReadCartItems() === null ? 0 : ReadCartItems().length}  </span>
                                                                        </Link>
                                                                    }
                                                                    {(ReadCartItems() !== null && ReadCartItems().length > 0) ?
                                                                        <div className="cart-contents" id="DivCartContent">
                                                                            <div className="widget ciyashop widget-shopping-cart">
                                                                                <div className="widget-shopping-cart-content">
                                                                                    <div className="pgs-product-list-widget-container has-scrollbar">
                                                                                        <ul className="ciyashop-mini-cart cart-list">
                                                                                            {ReadCartItems().map((CartItem, index) => (

                                                                                                <li className="ciya-mini-cart-item" key={index}>
                                                                                                    <Link to="#" onClick={() => removeFromCart(index)} id={`Product_${CartItem.ProductID}`} className="remove remove_from_cart_button">×</Link>
                                                                                                    <div className="media">
                                                                                                        <Link to="#"><img width={60} height={76} src={`${Domain}${CartItem.ProductImage}`} className="img-fluid" alt="pic" /></Link>
                                                                                                        <div className="media-body">
                                                                                                            <Link to="#" className="product-title">{CartItem.ProductName}</Link>
                                                                                                            <span className="quantity">{CartItem.Qty} × <span className="woocs-special_price_code"><span className="ciya-Price-amount amount"><span className="ciya-Price-currencySymbol">$</span>{CartItem.Rate}</span></span></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            ))}

                                                                                        </ul>
                                                                                    </div>
                                                                                    <p className="ciyashop-mini-cart__total total"><strong>Subtotal:</strong> <span className="woocs_special_price_code"><span className="ciyashop-Price-amount amount"><span className="ciyashop-Price-currencySymbol">$</span> {ReadCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * CartItem.Rate), 0)}</span></span></p>
                                                                                    <p className="ciyashop-mini-cart__buttons buttons">
                                                                                        <Link onClick={() => HideCart()} to="/ShopingCart" className="button wc-forward">View cart</Link>
                                                                                        <Link onClick={() => HideCart()}  to="/CheckOut" className="button checkout wc-forward">Checkout</Link>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        :
                                                                        <div className="cart-contents" id="DivCartContent">
                                                                            <div className="widget ciyashop widget-shopping-cart">
                                                                                <div className="widget-shopping-cart-content">
                                                                                    <p className="ciyashop-mini-cart__total total">
                                                                                    <img src={require(`../../assets/images/empty-cart.png`)} className="img-fluid mr-3" alt= 'empty cart'/>
                                                                                    <strong>Your cart is currently empty.</strong> <span className="woocs_special_price_code"><span className="ciyashop-Price-amount amount"><span className="ciyashop-Price-currencySymbol"></span> </span></span></p>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </li>
                                                                 <li className="ciya-tools-action ciya-tools-search"><Link to="/shop"><i className="glyph-icon pgsicon-ecommerce-magnifying-glass"  /></Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Navbar color="faded" light >

                                            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                                            <Collapse isOpen={!collapsed} navbar>
                                            <Nav className="ml-auto" navbar>
                                                <li className={`nav-item ${(classset === 'Home') ? 'show' : '' }`}>
                                                <Fragment>
                                                    <NavItem>
                                                        <Link to='/' className="nav-admin-link" >Home</Link>
                                                    </NavItem>
                                                </Fragment>
                                                </li>
                                                <li className={`nav-item ${(classset === 'Shop') ? 'show' : '' }`}>
                                                <Fragment>
                                                        <Link to="#" className="nav-link" onClick={()=> onClickClassAdd('Shop')}>Shop</Link>
                                                        <ul className={(classset === 'Shop') ? 'showcollapsed' : 'submenu' }>
                                                            {categories && categories.map((subNavLink, index) => (
                                                                <li key={index} className={`nav-item  ${(pageName === subNavLink.name) ? 'active' : '' }`} toggle='false'>
                                                                    <Link className="nav-link"  onClick={() => closeNavbar()} to={`/shop?cat_id=${subNavLink.id}`}>{subNavLink.name}</Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Fragment>
                                                </li>
                                                <li className={`nav-item ${(classset === 'Blog') ? 'show' : '' }`}>
                                                <Fragment>
                                                    <NavItem>
                                                        <Link to='/Contactus' className="nav-admin-link">Contact Us</Link>
                                                    </NavItem>
                                                </Fragment>
                                                </li>
                                            </Nav>
                                            </Collapse>
                                        </Navbar>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Modal isOpen={modal} toggle={myToggler} className="modal-login modal-dialog-centered">
                                        <ModalHeader toggle={myToggler}>
                                            <h4 className="mb-0">Sign in Or Register</h4>
                                        </ModalHeader>
                                        <ModalBody>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '1' })}
                                                        onClick={() => { logintoggle('1'); }}
                                                    >
                                                        Sign In
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '2' })}
                                                        onClick={() => { logintoggle('2'); }}
                                                    >
                                                        Register
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1">
                                                    <form onSubmit= {e => loginSubmit(e)}>
                                                        <div className="form-group">
                                                            <label>Email address</label>
                                                            <input type="text" className="form-control" name='email' placeholder="Enter email"></input>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Password </label>
                                                            <input type="password" className="form-control" name='password' placeholder="Password"></input>
                                                        </div>
                                                        <div className="form-group">
                                                            <p className="mb-0 ml-1"><Link to="/passwordreset" target='_blank'>Forgot Password?</Link></p>
                                                            <Button className="btn btn-primary mt-1" type="submit">Log in</Button>
                                                            <Link to="#" className="btn btn-secondary ml-2 mt-1" onClick={myToggler} >Cancel</Link>
                                                        </div>
                                                        <p className="mb-0">Don't have account? <Link to="#" className={classnames({ active: activeTab === '2' })}
                                                            onClick={() => { logintoggle('2'); }} > Register </Link>here</p>
                                                    </form>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <form onSubmit= {e => onSignup(e)}>
                                                        <div className="form-group">
                                                            <label>Full Name</label>
                                                            <input type="text" className="form-control" placeholder="Full name" name="fullName" required></input>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Email address</label>
                                                            <input type="text" className="form-control" placeholder="Enter email" name='email' required></input>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Password </label>
                                                            <input type="password" className="form-control" placeholder="Password" name='password' required></input>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Confirm Password </label>
                                                            <input type="password" className="form-control" placeholder="Confirm Password" name='password2' required></input>
                                                        </div>
                                                        <div className="form-group p-2" style={{display: 'flex'}}>
                                                            {wait ?
                                                            <Spinner style={{margin: 'auto', color: '#04d39f'}}/>:
                                                            <>
                                                            <Button className="btn btn-primary" type="submit">Sign Up</Button>
                                                            <Link to="#" className="btn btn-secondary mx-2" onClick={myToggler} >Cancel</Link>
                                                            </>}
                                                        </div>
                                                        <p className="mb-0">Already have account? <Link to="#" className={classnames({ active: activeTab === '1' })}
                                                            onClick={() => { logintoggle('1'); }} > SignIn </Link> here </p>
                                                    </form>
                                                </TabPane>
                                            </TabContent>
                                        </ModalBody>
                                    </Modal>
                                    <div className="col-12">
                                        <div className="mobile-menu" id="mobileMenu" />
                                     </div>
                                </Row>
                            </div>
                        </div>
                    </div>
            </header>
        )
};
export default Header;
