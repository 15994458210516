/**
 *  Account Profile
 */
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col,Container, Button } from 'reactstrap';
import history from '@history'
import StarRatingComponent from 'react-star-rating-component';
import * as Actions from 'store/actions'
import './review.css'

function ReviewProduct(props) {
  const dispatch = useDispatch();
  const [review, setReview] = useState(null)
  const [rating, setRating] = useState(5)
  const ProfileData = useSelector(({auth}) => {
    return auth.login.success ? auth.user : false
  })
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  const product_id = props.location.state.productId ? props.location.state.productId : null
  const order_id = props.location.state.orderId ? props.location.state.orderId : null
  const productOrder_id = props.location.state.productOrder_id ? props.location.state.productOrder_id : null
  const handleStars = e => {
    setRating(e)
  }
  const handleChange = (e) => {
    setReview({
      ...review,
      [e.target.name] : e.target.value
    })
  }
  const onFormSubmit = e => {
    e.preventDefault();
    if(product_id !== null && order_id !== null && productOrder_id !== null){
      review.rating = rating;
      review.product_id = product_id
      review.order_id = order_id
      review.productOrder_id = productOrder_id
      console.log(review);
      dispatch(Actions.submitProductReview(review))
    } else {
      console.log("No product Found");
    }
  }
  if(ProfileData === false){
    history.push({
      pathname: '/'
    })
  }
  return (
      <div>
        <div className="inner-intro">
          <Container>
            <Row className="intro-title align-items-center">
              <Col md={6} className="text-left">
                <div className="intro-title-inner">
                <h1>Product Review</h1>
                </div>
              </Col>
              <Col md={6}  className="text-right">
                  <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                  <li className="home">
                      <span>
                      <Link className="bread-link bread-home" to="/">Home</Link>
                      </span>
                  </li>
                  <li><span>Product Review</span></li>
                  </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
        <div className="product-content-bottom">
            <Row>
              <Col md='8'>
                <div className="product-reviews ml-4">
                <h6>Add a Review</h6>
                <p>Your email address will not be published. Required fields are marked *</p>
                <form onSubmit= {e => onFormSubmit(e)}>
                    <div className="form-group">
                    <label>Name</label>
                    <input type="Text" className="form-control" placeholder= 'Name (optional)' name= 'name' onChange={e => handleChange(e)}></input>
                    </div>
                    <div className="form-group">
                    <label>Email</label>
                    <input type="Text" className="form-control" placeholder= 'Email (optional)' name='email' onChange={e => handleChange(e)}></input>
                    </div>
                    <div className="form-group star-div">
                    <label className="mb-0">Your rating *</label>
                    <StarRatingComponent 
                      name="rating"
                      value={rating}
                      onStarClick={e =>handleStars(e)}
                      editing ={true}
                    />
                    </div>
                    <div className="form-group">
                    <label>Your review *</label>
                    <textarea className="form-control" rows="3" name= 'comment' onChange={e => handleChange(e)} required></textarea>
                    </div>
                    <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1"></input>
                    <label className="form-check-label" for="exampleCheck1">Save my name, email, and website in this browser for the next time I comment.</label>
                    </div>
                    <div className="form-group">
                    <Button className="btn btn-primary mr-2" type='submit'>Submit</Button>
                    <Link className="btn btn-primary" to= '/Account/OrderHistory'>Go Back</Link>
                    </div>
                </form>
                </div>
              </Col>
            </Row>
        </div>
        </Container>
      </div>
        )
    }
export default ReviewProduct;
