import React, { useState, useEffect } from 'react'
import {Col, Container, Row, Button, FormGroup} from 'reactstrap'
import GoogleMapReact from 'google-map-react'
import { Link } from 'react-router-dom'

function ContactUs(){
    const [message, setMessage] = useState(null)
    useEffect(() => {
        window.scrollTo(0,0)
    })

    const handleMessage = e => {
        setMessage({
            ...message,
            [e.target.name] : e.target.value
        })
    }
    const onMessageSubmit = e => {
        e.preventDefault()
        console.log(message);
    }
    return(
        <div>
          <div className="inner-intro">
            <Container>
                <Row className="intro-title align-items-center">
                    <Col md={6} className="text-left">
                        <div className="intro-title-inner">
                        <h1>Contact Us</h1>
                        </div>
                    </Col>
                    <Col md={6}  className="text-right">
                        <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                        <li className="home">
                            <span>
                            <Link className="bread-link bread-home" to="/">Home</Link>
                            </span>
                        </li>
                        <li><span>Contact Us</span></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            </div>
            <div className="section-ptb">
            <Container>
             <Row>
              <Col md={6} sm={12} className="mt-4 mt-lg-0">
                  <div>
                    <div className="woocommerce-Address-title">
                      <h3 className="mb-0 py-2">Leave us a Message</h3>
                    </div>
                    <div className="woocommerce-Address-info mt-4">
                    <form onSubmit={e => onMessageSubmit(e)}>
                        <Row form>
                            <Col md={6}>
                            <FormGroup>
                                <label>Full Name *</label>
                                <input type="text" className="form-control" name="fullName" onChange={e => handleMessage(e)} required/>
                            </FormGroup>
                            </Col>
                            <Col md={6}>
                            <FormGroup>
                                <label>Email *</label>
                                <input type="text" className="form-control" name="email" onChange={e => handleMessage(e)} required />
                            </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <label>Subject *</label>
                            <input type="text" className="form-control" name="subject" onChange={e => handleMessage(e)} required />
                        </FormGroup>
                        <FormGroup>
                            <label>Your Message *</label>
                            <textarea className="form-control" rows="3" name= 'message' onChange={e => handleMessage(e)} required></textarea>
                        </FormGroup>
                        <Button type="submit" className="btn btn-primary">Send</Button>
                    </form>
                    </div>
                  </div>
              </Col>
              <Col md={6} sm={12} className="mt-4 mt-lg-0">
                <div style= {{height: '45vh'}}>
                    <GoogleMapReact
                        defaultCenter={{
                            lat: 33.649,
                            lng: 73.152
                        }}
                        defaultZoom={15}
                    >
                    </GoogleMapReact>
                </div>
              </Col>
            </Row>
            </Container>
            </div>
        </div>
    )
}

export default ContactUs