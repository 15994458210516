/**
 *  Account Profile
 */
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col,Container } from 'reactstrap';
import Sitebar from './Sitebar';
import history from '@history'
import Loader from 'react-loader-spinner';

function AccountProfile() {
  const [Profile, setProfile] = useState(null)
  const ProfileData = useSelector(({auth}) => {
    return auth.login.success ? auth.user : false
  })
  useEffect(() => {
    window.scrollTo(0, 0)
    if(ProfileData){
        setProfile(ProfileData)
    }
  },[ProfileData])
  if(ProfileData === false){
    history.push({
      pathname: '/'
    })
  }
  return (
      <div>
        <div className="inner-intro">
          <Container>
            <Row className="intro-title align-items-center">
              <Col md={6} className="text-left">
                <div className="intro-title-inner">
                <h1>My Account</h1>
                </div>
              </Col>
              <Col md={6}  className="text-right">
                  <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                  <li className="home">
                      <span>
                      <Link className="bread-link bread-home" to="/">Home</Link>
                      </span>
                  </li>
                  <li><span>My Account</span></li>
                  </ul>
              </Col>
            </Row>
          </Container>
        </div>
        
            <div className="section-ptb">
            <Container>
            <Row>
                <Sitebar />
                <Col lg={9} className="mt-4 mt-lg-0">
                <Row>
                    <Col lg={12}>
                        {Profile && Profile !== null ?
                        <div className="woocommerce-Address">
                            <div className="woocommerce-Address-title">
                            <h5 className="mb-0">Profile Information</h5>
                            <Link className="edit" to="/Account/AccountProfileedit">Edit</Link>
                            </div>
                            <div className="woocommerce-Address-info mt-4">
                            <ul className="list-unstyled mb-0">
                                <li><span>Full name:</span><strong>{Profile.data.fullName}</strong></li>
                                <li><span>Phone no:</span><strong>{Profile.data.phone}</strong></li>
                                <li><span>Email:</span><strong>{Profile.data.email}</strong></li>
                            </ul>
                            </div>
                        </div>:
                        <div className='custom-loader'>
                            <Loader type="Puff" color="#04d39f" height={100} width={100} />
                        </div>}
                    </Col>
                </Row>
                </Col>
            </Row>
            </Container>
        </div>
      </div>
        )
    }
export default AccountProfile;
