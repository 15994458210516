import Domain from 'config.js'
import axios from 'axios';
import * as authActions from 'auth/store/actions'
import { toast } from 'react-toastify';
import history from '@history'
export const PROFILE_UPDATE = '[APP] PROFILE_UPDATE';
export const SIGNUP_CUSTOMER = '[APP] SIGNUP_CUSTOMER';
export const RESEND_EMAIL = '[APP] RESEND_EMAIL';
export const RESET_PASSWORD = '[APP] RESET_PASSWORD';
export const SET_LOADING = '[APP] SET_LOADING';

export function updateCustomerProfile(data) {
    const request = axios.put(`${Domain}/buyer/updateCustomer`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: PROFILE_UPDATE
            })
        }
        ).then(() => toast.success("Profile Successfully Updated"))
        .then(() => window.location.reload())
        .catch(error => {
            console.log(error);
            toast.warn('Cannot Update Profile')
        })
    )
}

export function signupCustomer(data){
    const request = axios.post(`${Domain}/buyer/signupCustomer`, data)
    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: SIGNUP_CUSTOMER
            })
        }
        )
        .then(() => dispatch(loadingToggle()))
        .then(() => dispatch(authActions.modelToggle()))
        .then(() => history.push({
            pathname: '/emailverifyPage'
        }))
        .catch(error => {
            dispatch(loadingToggle())
            toast.warn(error.response.data.msg)
        })
    )
}
export function resendVerifyEmail(email){
    const request = axios.post(`${Domain}/buyer/resendVerifyEmail`, {email})
    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: RESEND_EMAIL
            })
        }
        )
        .then(() => toast.success("Email resent successfully"))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot resend email')
        })
    )
}
export function passwordRest(email){
    const request = axios.post(`${Domain}/buyer/resetPassword`, {email})
    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: RESET_PASSWORD
            })
        }
        )
        .then(() => dispatch(loadingToggle()))
        .then(() => toast.success("Email resent successfully"))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot resend email')
        })
    )
}
export function updatepassword(data){
    const request = axios.post(`${Domain}/buyer/updatepassword`, data)
    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: RESET_PASSWORD
            })
        }
        )
        .then(() => dispatch(loadingToggle()))
        .then(() => toast.success("Password updated successfully"))
        .then(() => history.push({pathname: '/'}))
        .catch(error => {
            dispatch(loadingToggle())
            console.log(error);
            toast.warn('Cannot updated Password')
        })
    )
}

export function loadingToggle(){
    return (dispatch) => (
        dispatch({
            type: SET_LOADING
        })
        )
}