import * as Actions from '../actions';

const initialState = {
    success: false,
    modalToggle: false,
    error: null
};

const login = function (state = initialState, action) {
    switch (action.type) {
        case Actions.LOGIN_SUCCESS:
            {
                return {
                    ...initialState,
                    success: true
                };
            }
        case Actions.LOGIN_ERROR:
            {
                return {
                    success: false,
                    modalToggle: true,
                    error: action.payload
                };
            }
            
        case Actions.USER_LOGGED_OUT:
            {
                return initialState;
            }
        case Actions.MODAL_TOGGLE:
            {
                return {
                    ...state,
                    modalToggle: !state.modalToggle
                }
            }
        default:
            {
                return state
            }
    }
};

export default login;