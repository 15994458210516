import React from 'react';
import './pagesStyle.css'
function SideTray(){
    var switchs = true;
    const changeclass = () => {
        var swtich = document.getElementsByClassName("switch-btn");
        if(switchs){
            document.querySelector(".side-tray").classList.add('active');
            switchs = false;
        } else {
            document.querySelector(".side-tray").classList.remove('active');
            switchs = true;
        }
    }

    return(
        <div className="side-tray">
            <a href="javascript:void(0);" onClick={() => changeclass()} class="switch-btn"><i class="fa fa-angle-right"></i></a>
            <div>
                <h2 className="title">Please contact at:</h2>
                <p className="text-dark">Email : contact@infinitybits.pk</p>
                <p className="text-dark">Phone : +92-332-5409-980</p>
            </div>
        </div>
    )
}

export default SideTray;