import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import 'styles.js'
import Header from 'components/Layouts/Header';
import HomePage from 'components/Home';
import ProductDetail from 'components/Shop/ProductDetail';
import ShopingCart from 'components/Shop/ShoppingCart';
import CheckOut from 'components/Shop/CheckOut';
import SuccessScreen from 'components/Shop/SuccessScreen';
import AccountProfile from 'components/Account/AccountProfile';
import Address from 'components/Account/Address';
import AccountProfileedit from 'components/Account/AccountProfileEdit';
import AddressEdit from 'components/Account/AddressEdit';
import OrderHistory from 'components/Account/OrderHistory';
import ReviewProduct from 'components/ReviewProduct/ReviewProduct';
import ShopPage from 'components/Shop';
import Footer from 'components/Layouts/Footer';
import PageNotFound from 'components/Pages/PageNotFound';
import ContactUs from 'components/Pages/ContactUs';
import EmailVerification from 'components/Pages/EmailVerification';
import PaymentScreen from 'components/Shop/PaymentScreen';
import PasswordReset from 'components/Pages/ResetPassword';
import ChangePassword from 'components/Pages/ChangePassword';
import AdminDashboard from 'components/Admin';
import SideTray from 'components/Pages/SideTray';

function App(props) {
  const getUrl = (pathname) => {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/passwordreset'  ? true : `/${pathArray[1]}` === '/emailverifyPage' ? true :`/${pathArray[1]}` === '/updatepassword'  ? true : `/${pathArray[1]}` === '/admin'  ? true :false;
  }
  console.log(props);
  return (
    <div className="App">
      {
        getUrl(props.location.pathname) ?
        <Switch>
          <Route exact path="/passwordreset" render={props => <PasswordReset {...props} />} />
          <Route exact path="/emailverifyPage" render={props => <EmailVerification {...props} />} />
          <Route exact path="/updatepassword" render={props => <ChangePassword {...props} />} />
          <Route path="/admin" component={AdminDashboard} />
        </Switch>
        :
        <div>
          <Header />
          <SideTray />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/shop" exact render={props => <ShopPage {...props} />} />
            <Route exact path={`/shop/productDetail/:id`} render={props => <ProductDetail {...props} />} />
            <Route path="/ShopingCart" exact render={props => <ShopingCart {...props} />} />
            <Route path="/checkOut" exact render={props => <CheckOut {...props} />} />
            <Route path="/payment" exact render={props => <PaymentScreen {...props} />} />
            <Route path="/SuccessScreen" exact render={props => <SuccessScreen {...props} />} />

            <Route path="/Account/AccountProfile" exact render={props => <AccountProfile {...props} />} />
            <Route path="/Account/AccountProfileedit" exact render={props => <AccountProfileedit {...props} />} />
            <Route path="/Account/Address" exact render={props => <Address {...props} />} />
            <Route path="/Account/Addressedit" exact render={props => <AddressEdit {...props} />} />

            <Route path="/Account/OrderHistory" exact render={props => <OrderHistory {...props} />} />
            <Route exact path="/product/review" render={props => <ReviewProduct {...props} />} />

            <Route exact path="/Contactus" render={props => <ContactUs {...props} />} />

            <Route exact path= "/404" component ={PageNotFound} />
            <Redirect to='/404' />
          </Switch>
          <Footer />
        </div>
      }
    </div>
  );
}

export default App;
