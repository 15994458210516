
/**
 *  Shop Main Page
 */
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import SideFilter from 'widgets/shopfilter/SideFilter';
import { Link } from 'react-router-dom';
import { Row, Col,Container } from 'reactstrap';
import ProductList from 'widgets/ProductList';
import TopFilter from 'widgets/shopfilter/TopFilter';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner';
import * as queryString from 'query-string';
import ShopBanner from 'widgets/shopfilter/ShopBanner';
import Socialinfo from 'widgets/shopfilter/SocialInfo'

function ShopPage(props) {
    const {cat_id} = queryString.parse(props.location.search)
    const [limit, setLimit] = useState(8)
    const [hasMoreProduct, setHasMoreProduct] = useState(true)
    const [products, setProducts] = useState([])
    const [productCount, setProductCount] = useState(0)
    const [categories, setCategories] = useState(null)
    const productData = useSelector(({app}) => app.Products)
    const categoryData = useSelector(({app}) => app.Categories)
    useEffect(() => {
        setProducts(productData.rows)
        setProductCount(productData.totalProducts)
    },[productData])
    useEffect(() => {
        setCategories(categoryData)
    },[categoryData])
    const fetchProduct = () => {
        if (limit >= products.length) {
            setHasMoreProduct(false)
            return;
        }
        setLimit(limit+ 5)
    }
    let layoutstyle=localStorage.getItem('setLayoutStyle')

    if(layoutstyle == null)
    {
        layoutstyle=localStorage.setItem('setLayoutStyle','col-sm-6 col-md-4')
    }
    if(categories === null){
        return (
        <div id="preloader">
            <Loader type="Puff" color="#04d39f" height={100} width={100} />
        </div>
        )
    }
    return (
        <div className="site-content">
            <div className="inner-intro">
                <Container>
                    <Row className="intro-title align-items-center">
                        <Col md={6} className="text-left">
                            <div className="intro-title-inner">
                            <h1>Shop</h1>
                            </div>
                        </Col>
                        <Col md={6}  className="text-right">
                            <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                            <li className="home">
                                <span>
                                <Link className="bread-link bread-home" to="/">Home</Link>
                                </span>
                            </li>
                            <li><span>Products</span></li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="content-wrapper section-pt mb-3 mb-md-5">
                <Container>
                    <Row>
                        <div className="sidebar col-xl-3 col-lg-4 desktop">
                            <div className="shop-sidebar-widgets">
                                <SideFilter categories={categories} cat_id= {cat_id}/>
                                <Socialinfo />
                                <ShopBanner />
                            </div>
                        </div>
                        <div className="content col-xl-9 col-lg-8">
                            <div className="products-header">
                                <div className="right-banner">
                                    <img alt="Shop Banner" src={require(`../../assets/images/shop/shop-banner.jpg`)}  className="img-fluid" />
                                </div>
                                <div className="loop-header">
                                    <div className="loop-header-tools">
                                        <div className="loop-header-tools-wrapper">
                                            <TopFilter productlength={productCount}  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {productCount > 0 ?
                                <InfiniteScroll
                                    dataLength={limit}
                                    next={fetchProduct}
                                    hasMore={hasMoreProduct}
                                    loader={<div className="lazyload-img"></div>}>
                                    <Row className="products products-loop grid ciyashop-products-shortcode pgs-product-list">
                                        {products.slice(0,limit).map((product, index) =>
                                            <ProductList product={product} key={index} layoutstyle={layoutstyle} />
                                            )
                                        }
                                    </Row>
                                </InfiniteScroll>
                            :
                                    <Row className="products products-loop grid ciyashop-products-shortcode">
                                    <div className="col-sm-12 text-center  mt-5" >
                                        <img src={require(`../../assets/images/empty-search.jpg`)} className="img-fluid mb-4" alt="notfound"/>
                                        <h3>Sorry! No products were found matching your selection!    </h3>
                                        <p>Please try to other words.</p>
                                            <Link to="/" className="btn btn-solid">Continue Shopping</Link>
                                    </div>
                                    </Row>
                            }
                        </div>

                    </Row>
                </Container>
            </div>
        </div>
      )
}

export default ShopPage