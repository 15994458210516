import React from 'react'
import { Route } from "react-router-dom";
import AdminLogin from './AdminLogin/AdminLogin'
import AdminHeader from './Adminheader';
import AdminInvoices from './AdminInvoices';
import AdminProduct from './AdminProductList';
import Productedit from './Product/Productedit';
import Productadd from './Product/Productadd';
import CategoryPage from './CategoryPage';
import Reports from './Reports';
function AdminDashboard(props){
    const getUrl = (pathname) => {
        return pathname === "/admin" ? true : false
      }
    const { match } = props
    return(
        <div>
            {getUrl(props.location.pathname) ?
            <div>
                <Route exact path={`${match.url}`} render={props => <AdminLogin {...props} />} />
            </div>
            :
            <div>
                <AdminHeader />
                <Route exact path={`${match.url}/dashboard`} render={props => <Reports {...props} />} />
                <Route exact path={`${match.url}/invoices`} render={props => <AdminInvoices {...props} />} />
                <Route exact path={`${match.url}/Product`} render={props => <AdminProduct {...props} />} />
                <Route exact path={`${match.url}/Product-edit/:id`} render={props => <Productedit {...props} />} />
                <Route exact path={`${match.url}/product-add`} render={props => <Productadd {...props} />} />
                <Route exact path={`${match.url}/category`} render={props => <CategoryPage {...props} />} />
            </div>
            }
        </div>
    )
}
export default AdminDashboard