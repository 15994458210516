import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import * as Actions from 'auth/store/actions'
import history from '@history';
import './loginStyle.css'
import 'animate.css'

function AdminLogin() {
    const dispatch = useDispatch()
    const Auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    useEffect(() => {
        if(Auth && Auth.role === 'admin'){
            history.push({
                pathname: '/admin/dashboard'
            })
        }
    },[Auth])
    const loginSubmit = (e) => {
        e.preventDefault()
        const loginData = {
            username : e.target.username.value,
            password: e.target.password.value
        }
        console.log(loginData)
        dispatch(Actions.loginAdmin(loginData))
    }
    return (
    <div>
        <div className="main-container">
            <div className="bg-div">
                <div className="bg-content animated bounceInLeft">
                    <img src={require('assets/images/main-logo-fff.png')} alt="main-logo2" width='50%'/>
                    <h6 className='lorem-h6'>At Infinity Bits, we have developed an 
                    E-commerce Marketplace with featured support of managing Sellers 
                    and Buyers specific to a business. The idea is to help people who 
                    are interested in launching dedicated online stores with full-featured 
                    control to digitize their routine business by utilizing e-commerce apps.
                    </h6>
                </div>
            </div>
            <div className="login-panel">
            <div className="login-content">
                <div className="tabs-div">
                    <h2 className='text-center my-4'>Admin Login</h2>
                    <form onSubmit={e => loginSubmit(e)}>
                        <div className='input-groups'>
                            <label>Username</label>
                            <input type="text" name="username" placeholder='Username' className='theinputs'/>
                        </div>
                        <div className='input-groups'>
                            <label>Password</label>
                            <input type="password" name="password" placeholder='Password' className='theinputs'/>
                        </div>
                        <div className="input-groups">
                            <Button color= 'primary' className='btn-login'>Login</Button>
                        </div>
                    </form>
                    <div className="text-center"><Link to='/' style={{color: '#192d3e'}}>Go back home?</Link></div>
                </div>
            </div>
            </div>
        </div>
    </div>
    )
}
export default AdminLogin