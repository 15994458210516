import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactTable from 'react-table-6'
import { Link } from 'react-router-dom';
import { Row, Col,Container,Form,FormGroup, Label, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import * as Actions from 'store/actions'
import { toast } from 'react-toastify';

function CategoryPage(){
    const dispatch = useDispatch();
    const [cat, setCat] = useState(null)
    const [edit, setEdit] = useState(null)
    const [editModal, setEditModal] = useState(false)
    const catData = useSelector(({admin}) =>{
        return admin ? admin.Categories : null
    })
    useEffect(() => {
        setCat(catData)
    }, [catData])

    const onAddCat = e => {
        e.preventDefault()
        let name = e.target.category.value
        console.log(name);
        dispatch(Actions.adminAddCategory(name))
        document. getElementById("myForm").reset()
    }
    const openModal = data => {
        setEdit(data)
        editToggle()
    }
    const editToggle = () => {
        setEditModal(!editModal)
    }
    const onSumitRename = e => {
        e.preventDefault()
        let data = {
            id : edit.id,
            name : e.target.catgry.value ? e.target.catgry.value : edit.name
        }
        dispatch(Actions.adminEditCategory(data))
        editToggle()
        console.log(data);
    }

    const columns = [
        {
            maxWidth: 75,
            Header: 'No.',
            accessor: 'id',
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Created At',
            accessor: 'created_at',
        },
        {
            Header: 'Rename',
            accessor: 'rename',
            maxWidth: 140,
            style : {
              textAlign: "right"
            },
            Cell: props => {
                return (
                <div>
                    <Link to='#' className="view-button" onClick={() => openModal(props.original)}>Rename<i className="fa fa-cog pl-2"></i></Link>
                 </div>
                 )
                },
        },
        {
            Header: 'Delete',
            accessor: 'delete',
            maxWidth: 140,
            Cell: props => {
                return (
                <div>
                    <Link to='#' className="view-button" onClick={() => toast.error("Please Dont Delete Me :-(")}>Delete<i className="fa fa-trash pl-2"></i></Link>
                 </div>
                 )
                },
        }
     ]

    return(
        <div className="section-ptb">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="mb-0">
                            <h4>Add Category</h4>
                        </div>
                        <div className="mb-4">
                            <form onSubmit={e => onAddCat(e)} id="myForm">
                            <Row form>
                            <Col md={6}>
                                <div className="form-group">
                                    <input type="text" className="form-control" name="category"  placeholder="Add Category..."></input>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Button color='primary' type='submit'>Add</Button>
                            </Col>
                            </Row>
                            </form>
                        </div>
                        {cat === null ?
                        null:
                        <ReactTable className="invoices-table"
                            data={cat}
                            columns={columns}
                            minRows={1}
                            showPagination={false}
                            />}
                        </Col>
                </Row>
            </Container>
            <Modal isOpen={editModal} toggle={editToggle} className="modal-view modal-lg modal-dialog-centered">
                <ModalHeader toggle={editToggle}></ModalHeader>
                <ModalBody>
                  <div className="success-screen">
                      <div className="delivery p-4 p-md-5 text-center" style={{background: '#04d39f'}}>
                        <span className="h3 text-white">Edit Category</span>
                      </div>
                  </div>
                  <div className="pt-md-3 px-md-3 pb-3">
                    {edit === null ? null :
                        <Form onSubmit={e => onSumitRename(e)}>
                        <FormGroup>
                        <Label for="exampleSelect" md={6}>Edit Category</Label>
                        <Col md={6}>
                            <Input type="text" name="catgry" defaultValue={edit.name}/>
                        </Col>
                        </FormGroup>
                        <div className='px-md-3'>
                        <Button type='submit' color='primary'>Save</Button>
                        <Button color='secondary' className='ml-2' onClick={e => editToggle()}>Cancel</Button>
                        </div>
                    </Form>
                    }
                  </div>
                </ModalBody>
              </Modal>
        </div>
    )
}

export default CategoryPage;