import Domain from 'config.js'
import axios from 'axios';
import { toast } from 'react-toastify';
import history from '@history';
export const GET_SHIPPINGCOST = '[APP] GET_SHIPPINGCOST';
export const PLACE_ORDER = '[APP] PLACE_ORDER';
export const GET_CUSTOMER_ORDERS = '[APP] GET_CUSTOMER_ORDERS';
export const SUBMIT_PRODUCT_REVIEW = '[APP] SUBMIT_PRODUCT_REVIEW';
export const CANCEL_ORDER = '[APP] CANCEL_ORDER';
export const ADD_EMAIL = '[APP] ADD_EMAIL';
export const GET_ORDERS_ADMIN = '[ADMIN] GET_ORDERS_ADMIN';
export const ADMIN_ORDER_LOADING = '[ADMIN] ADMIN_ORDER_LOADING';
export const ADMIN_UPDATE_ORDER = '[ADMIN] ADMIN_UPDATE_ORDER';
export const ORDERS_COUNT_GRAPH = '[ADMIN] ORDERS_COUNT_GRAPH';
export const LOADING_COUNT_GRAPH = '[ADMIN] LOADING_COUNT_GRAPH';

export function getShippingCost() {
    const request = axios.get(`${Domain}/buyer/shippingCost`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_SHIPPINGCOST,
                payload: response.data.shippingCost
            })
        }
        )
    )
}
export function submitEmail(email){
    const request = axios.post(`${Domain}/buyer/addMailChimp`, {email});
    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADD_EMAIL
            })
        }
        ).then(() => toast.success("Email Added successfully"))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot Add Email')
        })
    )
}
export function placeOrder(orderData) {
    const request = axios.post(`${Domain}/buyer/placeOrder`, orderData);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: PLACE_ORDER
            })
        }
        ).then(() => toast.success("Order placed successfully"))
        .then(() => {
            localStorage.removeItem("LocalCartItems");
            localStorage.setItem("FinalCheckoutCartItems", JSON.stringify(orderData))
            history.push({pathname : '/SuccessScreen'})
        })
        .catch(error => {
            console.log(error);
            toast.warn('Cannot place order')
        })
    )
}

export function getCustomerOrders(id) {
    const request = axios.post(`${Domain}/buyer/getCustomerOrders`, {id});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_CUSTOMER_ORDERS,
                payload: response.data.orders
            })
        }
        )
    )
}
export function cancelOrder(data) {
    const request = axios.put(`${Domain}/buyer/cancelOrder`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: CANCEL_ORDER,
            })
        }
        ).then(() => {
            toast.success("Order has been canceled")
            dispatch(getCustomerOrders(data.customer_id))
        }).catch(error => {
            console.log(error);
            toast.warn("Cannot cancel order")
        })
    )
}
export function submitProductReview(data) {
    const request = axios.post(`${Domain}/buyer/submitProductReview`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: SUBMIT_PRODUCT_REVIEW,
            })
        }
        ).then(() => toast.success('Thanks for your review'))
        .then(() => history.push({pathname: '/Account/OrderHistory'}))
        .catch((err) => {
            console.log(err.response.data.msg);
            toast.warn(err.response.data.msg)
        })
    )
}

export function getOrdersAdmin(page) {
    const request = axios.post(`${Domain}/admin/getOrdersAdmin`, {page});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_ORDERS_ADMIN,
                payload: response.data.result
            })
        }
        )
    )
}
export function adminOrderLoading(val){
    return (dispatch) => {
        return dispatch({
            type: ADMIN_ORDER_LOADING,
            payload : val
        })
    }
}

export function adminUpdateOrder(data) {
    const request = axios.put(`${Domain}/admin/adminUpdateOrder`, data)

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type : ADMIN_UPDATE_ORDER
            })
        })
        .then(() => dispatch(getOrdersAdmin(data.page)))
        .then(() => toast.success("Order status updated"))
        .catch(error => {
            console.log(error);
            toast.warn("Cannot update status")
        })
    )
}

export function orderCountGraph() {
    const request = axios.get(`${Domain}/admin/orderCountGraph`);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ORDERS_COUNT_GRAPH,
                payload: response.data.result
            })
        }
        )
        .catch(error => {
            console.log(error);
        })
    )
}

export function loadingCountGraph(val){
    return (dispatch) => {
        return dispatch({
            type: LOADING_COUNT_GRAPH,
            payload : val
        })
    }
}