/**
 * About Banner
 */
import React , {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class AboutBanner extends Component {

    constructor(){
        super();
        this.state = { showText: false };
      }
      getMoreTextDiv(){
        if (this.state.showText) {
            return  <p>We offer customization of the already developed baseline to save the cost and time of developing and launching a dedicated E-commerce 
            Marketplace for your business idea. The best part of this E-commerce Marketplace Product is that it is customizable and can be adapted 
            by any type of business i.e., clothing & apparel, accessories & gadgets, food & grocery, construction & real estate, and more. 
            Reach out to our mentors to discuss your requirements and get your own dedicated e-commerce 
            platform live with your desired customizations within a few days.</p>;
          } else {
            return null;
          }
      }
   render() {
    var expandedDiv = this.getMoreTextDiv();
    return (
        <Row className="section-ptb align-items-center">
            <Col md={6} className="order-md-1 mb-4 mb-md-0">
                <div className="single_image-wrapper">
                  <img src={require(`../assets/images/categories/default/about-img.png`)} alt="placeholder" className="attachment-full img-fluid" />
                </div>
            </Col>
            <Col md={6}>
                <div className="text-wrapper">
                   <span>Know more</span>
                </div>
                <div className="section-title mb-4">
                  <h2 className="title text-left"> About Ecom Store</h2>
                <div className="text-wrapper">
                    <p>At Infinity Bits, we have developed an E-commerce Marketplace with featured support of managing Sellers and Buyers specific to a business. 
                      The idea is to help people who are interested in launching dedicated online stores with full-featured control to digitize their routine business by utilizing e-commerce apps (Mobile, Web). 
                      The motive behind this product is to provide an All-in-one platform full of e-commerce options as provided by Amazon, AliExpress, 
                      Etsy, etc. to small-to-medium scale business entities who are interested in launching their own E-commerce Marketplace. 
                    </p>
                    { expandedDiv }
                 </div>
                 </div>
                <div className="ciyashop_button_wrapper ciyashop_button_width_default">
                    <div className="inline_hover ciyashop_button_link ciyashop_button_size_medium button-underline">
                        <Link style={{cursor : 'pointer'}} to= '#' className="inline_hover"  onClick={() => this.setState({ showText: !this.state.showText }) }> {(this.state.showText) ? "Read Less.." : "Read More.."} </Link>

                    </div>
                </div>
            </Col>
        </Row>
      )
   }
}

export default AboutBanner;
