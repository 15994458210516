import React from 'react';
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';

const mdParser = new MarkdownIt();
const plugins = ['header', 'fonts', 'table', 'link', 'clear', 'logger', 'mode-toggle'];
function MEditor(props){
  return (
    <MdEditor
      value={props.value}
      plugins={plugins}
      renderHTML={(text) => mdParser.render(text)}
      onChange={props.onChangeEditor}
      />
  )
}

export default MEditor