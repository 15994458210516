/**
 *  Admin Header
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container,Dropdown, DropdownToggle, DropdownMenu, DropdownItem,Navbar,NavbarToggler, Nav, NavItem, UncontrolledDropdown, Collapse } from 'reactstrap';
import logo from '../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import * as AuthActions from 'auth/store/actions'
import * as Actions from 'store/actions'
import Domain from 'config.js'
import history from '@history'

function AdminHeader(props) {
    const dispatch = useDispatch()
    const [dropdownOpen, setDropdownOpen] = useState(null)
    const [isOpen, setIsOpen] = useState(null)
    const [Profile, setProfile] = useState(null)
    const userData = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    useEffect(() => {
        if(userData){
            window.scrollTo(0, 0)
            dispatch(Actions.getAdminCategories())
            setProfile(userData.data)
        }
    }, [userData, dispatch])
    useEffect(() => {
        if (!userData || userData.role === 'customer') {
            history.push({
                pathname: '/'
            })
        }
    }, [userData]);
    const toggle2 = () => {
        setDropdownOpen(!dropdownOpen)
    }
    const toggle3 = () => {
        setIsOpen(!isOpen)
    }
    const Changeclass = (val) => {
        var removeelems = document.getElementsByClassName("nav-item");
        [].forEach.call(removeelems, function(el) {
            el.classList.remove('active');
        });

        if(val === "report")
        {
            document.querySelector(".report").classList.add("active");
        }
        if(val === "invoice")
        {
            document.querySelector(".invoice").classList.add("active");
        }
        if(val === "profile")
        {
            document.querySelector(".profile").classList.add("active");
        }
        if(val === "backhome")
        {
            document.querySelector(".backhome").classList.add("active");
        }
    }
    const logoutUser = () => {
        dispatch(AuthActions.logoutUser())
    }
    if(Profile === null){
        return (
            <div>
                <div id="preloader">
                    <Loader type="Puff" color="#04d39f" height={100} width={100} />
                </div>
            </div>
        )
    }
return (
        <div className="admin-menu">
        <Container>
            <Row className="align-items-center">
            <Col md={12}>
            <div className="d-flex align-items-center positive-reletive">

            <Link to="/"><img className="img-fluid logo" src={logo} alt="logo"/></Link>

            <Dropdown isOpen={dropdownOpen} toggle={toggle2} className="profile-dropdown ml-auto">
                <DropdownToggle caret className="btn-white">
                <img className="img-fluid rounded-circle profile-img" src={`${Domain}${Profile.photoURL}`} alt="profile"/>
                <div className="d-none d-sm-block">
                <h6 className="mb-0">{Profile.fullName}</h6>
                <span className="text-dark">{Profile.username}</span>
                </div>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => Changeclass('profile')}  className="nav-link"  tag={Link} to="/vendor/profile"><i className="fa fa-user-circle-o"></i>Profile</DropdownItem>
                    <DropdownItem onClick={() => Changeclass('profile')}className="nav-link"  tag={Link} to="/vendor/settings" disabled><i className="fa fa-cog"></i>Account settings</DropdownItem>
                    <DropdownItem onClick={logoutUser}  className="nav-link" tag={Link} to="/"><i className="fa fa-sign-out"></i>Logout</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            </div>
            <Navbar light expand="md"  className="bg-white">
                <NavbarToggler onClick={toggle3} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar>
                    <NavItem className="active report">
                        <Link  to="/admin/dashboard" className="nav-link" onClick={() => Changeclass('report')}><i className="fa fa-line-chart"></i>Reports</Link>
                    </NavItem>
                    <NavItem className="invoice">
                        <Link to="/admin/invoices" className="nav-link" onClick={() => Changeclass('invoice')}><i className="fa fa-inbox"></i>invoices</Link>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            <i className="fa fa-shopping-cart"></i>Products
                        </DropdownToggle>
                        <DropdownMenu left ='true'>
                            <DropdownItem onClick={() => Changeclass('product')} className="nav-link"   tag={Link} to="/admin/Product"><i className="fa fa-cart-plus"></i>Products</DropdownItem>
                            <DropdownItem onClick={() => Changeclass('product')} className="nav-link"   tag={Link} to="/admin/product-add"><i className="fa fa-cart-arrow-down"></i>Add Product</DropdownItem>
                            <DropdownItem onClick={() => Changeclass('product')} className="nav-link"   tag={Link} to="/admin/category"><i className="fa fa-cart-arrow-down"></i>Categories</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem className="profile">
                        <Link to="/admin/#" className="nav-link" onClick={() => Changeclass('profile')}><i className="fa fa-user-circle-o"></i>Profile</Link>
                    </NavItem>
                    <NavItem className="backhome">
                        <Link to="/" className="nav-link" onClick={() => Changeclass('backhome')}><i className="fa fa-home"></i>Back to home</Link>
                    </NavItem>
                    </Nav>
                </Collapse>
                </Navbar>
                </Col>
            </Row>
        </Container>
        </div>
        )
}
export default AdminHeader;
