/**
 *  Admin Site Product Listing Page
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container, Form, FormGroup, Button, Input, CustomInput } from 'reactstrap';
import AdminproductList from '../../widgets/AdminProduct';
import ReactPaginate from 'react-paginate';
import * as actions from 'store/actions'
import Loader from 'react-loader-spinner';
function AdminProduct() {
    const dispatch = useDispatch()
    const [Cat, setCat] = useState(null)
    const [productList, setProductList] = useState(null)
    const [totalProucts, setTotalProucts] = useState(null)
    const [page, setPage] = useState(0)
    const Auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    const Category = useSelector(({admin}) => admin.Categories)
    const Loading = useSelector(({admin}) => admin.AdminProducts.Loading);
    const AdminProducts = useSelector(({admin}) => {
        return admin ? admin.AdminProducts : false
    })
    useEffect(() => {
        if(Auth){
            dispatch(actions.adminProductLoading(true))
            dispatch(actions.getAdminProducts(page))
        }
    }, [Auth, dispatch, page])
    useEffect(() => {
        setCat(Category)
    },[Category])
    useEffect(() => {
        if(AdminProducts){
            setProductList(AdminProducts.rows)
            setTotalProucts(AdminProducts.totalProducts)
        }
    },[AdminProducts])
    const onProductSearch = (e) => {
        e.preventDefault()
        let data = {
            catId : e.target.category.value ? e.target.category.value : null,
            query : e.target.query.value ? e.target.query.value : null,
            promoted : e.target.promoted.checked,
            active : e.target.active.checked
        }
        console.log(data);
        dispatch(actions.adminProductLoading(true))
        dispatch(actions.adminProdByCat(data))
    }
    const onPageChange = pg => {
        setPage(pg.selected);
      };
    const onDeleteProduct = (id) => {
        if(id){
            console.log(id);
            dispatch(actions.deleteProduct(id))
        }
    }
    if(productList === null ){
        return (
            <div id="preloader">
            <Loader type="Puff" color="#04d39f" height={100} width={100} />
            </div>
        )
    }
    return (
        <div className="section-ptb">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="mb-0">
                            <h4>Product List</h4>
                        </div>
                        <div className="mb-4">
                            <Form onSubmit={e => onProductSearch(e)}>
                                <Row form>
                                <FormGroup className="mb-2 ml-2">
                                    <CustomInput type="switch" id="promoted" name="promoted" label="Promoted" />
                                </FormGroup>
                                <FormGroup className="mb-2 ml-4">
                                    <CustomInput type="switch" id="active" name="active" label="Active" defaultChecked={true}/>
                                </FormGroup>
                                </Row>
                                <Row form>
                                <Col md={4}>
                                    <FormGroup className="mb-2 mr-2 mb-sm-0">
                                    <Input type="text" name='query' placeholder="Search product..." />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <select name = 'category' className="form-control">
                                            <option value="">Select Category</option>
                                            {Cat && Cat!==null ?
                                            Cat.map(cat => (
                                            <option value={cat.id} key={cat.id}>{cat.name}</option>
                                            )) : <option value="cat.id">wait</option>}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                <Button type='submit' color='primary'>Search</Button>
                                </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className="mb-0 mb-md-4 mt-4">
                        {Loading ?
                        <div className="custom-loader">
                            <Loader type="Puff" color="#04d39f" height={100} width={100} />
                        </div>:
                        productList.length > 0 ?
                            <div>
                                <Row className="products products-loop grid ciyashop-products-shortcode pgs-product-list">
                                    {productList.map((product, index) =>
                                        <AdminproductList product={product} key={product.id} deleteproduct={()=>onDeleteProduct(product.id)} />
                                        )
                                    }
                                </Row>
                                <div className="ciyashop-pagination">
                                    <ReactPaginate
                                        pageRangeDisplayed={2}
                                        activeLinkClassName='current'
                                        initialPage={page}
                                        onPageChange={onPageChange}
                                        pageCount={Math.ceil(totalProucts/8)}
                                        />
                                </div>
                            </div>
                            :
                            <Row className="products products-loop grid ciyashop-products-shortcode">
                                <div className="col-sm-12 text-center  mt-4 mt-md-5" >
                                    <img src={require(`../../assets/images/empty-search.jpg`)} className="img-fluid mb-4" alt='empty-3'/>
                                    <h3>Sorry! No products were found matching your selection!    </h3>
                                    <p>Please try to other words.</p>
                                </div>
                            </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
export default AdminProduct;