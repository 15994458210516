/**
 *  Admin Site Product Edit Page
 */
import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'reactstrap';
import ProductEditDetail from '../../../templates/product-edit-detail';
import Loader from 'react-loader-spinner';
import * as Actions from 'store/actions'
function Productedit(props){
    const dispatch = useDispatch();
    const productId = parseInt(props.match.params.id);
    const theProduct = useSelector(({admin}) => admin.AdminProdDetail.details);
    const Loading = useSelector(({admin}) => admin.AdminProdDetail.Loading);
    const auth = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    });
    useEffect(() => {
        window.scrollTo(0, 0)
        if(auth){
            dispatch(Actions.adminProdDetailLoading(true))
            dispatch(Actions.adminProductDetail(productId))
        }
    }, [auth, dispatch, productId])
    if(!theProduct || theProduct === null){
        return(
        <div id="preloader">
            <Loader type="Puff" color="#04d39f" height={100} width={100} />
        </div>
        )}
    return(
        <div>
        {theProduct !== null ?
            <div className="site-content">
                <div className="content-wrapper section-ptb">
                    <Container>
                        {Loading ?
                        <div className="custom-loader">
                            <Loader type="Puff" color="#04d39f" height={100} width={100} />
                        </div>:
                        <ProductEditDetail product={theProduct} key= {theProduct.id}/>
                        }
                    </Container>
                </div>
            </div>
            :null}
        </div>
    )
}
export default Productedit;
