/**
 * Shop Page Side Bar Filter
 */
import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import * as AppActions from 'store/actions'
import { Button } from 'reactstrap';
import './styles.css'


function SideFilter(props) {
    const dispatch = useDispatch()
    const [SearchValue, setSearchValue] = useState('')
    const [value, setValue] = useState({min: 0, max: 2500})
    const [price, setPrice] = useState({min: 0, max: 2500})
    const [toFilterCat, setToFilterCat] = useState([props.cat_id]);
    const changeValue = (val) => {
            setValue({
                min: val.value.min,
                max: val.value.max,
            })
    }
    const priceSubmit = e => {
        e.preventDefault()
        setPrice(value)
    }
    const onClickCategoryFilter = (event) => {
        if (event.target.checked){
            setToFilterCat([...toFilterCat,event.target.value])
        }
        else{
            setToFilterCat(toFilterCat.filter(id => id !== event.target.value))
        }
    }
    const searchClick = (e) => {
        e.preventDefault()
        setSearchValue(e.target.searchText.value)
    }
    const removeSearch = e => {
        e.preventDefault()
        document.getElementById('search-inp').value = ''
        setSearchValue('')
    }
    useEffect(() => {
        var cleanArr = toFilterCat.filter(el => {
            return el != null;
       })
        dispatch(AppActions.getProductsByCat(cleanArr, price, SearchValue))
    },[toFilterCat, price, SearchValue, dispatch])
       return (
           <div>
                <div className="widget">
                <h4 className="widget-title">Search</h4>
                <form onSubmit={e => searchClick(e)} className='input-dyn'>
                    <input type="text" className="form-control" id='search-inp' name="searchText" placeholder="Search a Product" />
                    <span className='can-x' onClick={e => removeSearch(e)}>x</span>
                    <Button size='sm' outline className='mt-4 my-btna' type= 'submit'>Search</Button>
                </form>
                </div>
                <div className="widget widget_price_filter">
                    <h4 className="widget-title">Filter by Price</h4>
                    <div classs="shop-filter shop-filter-product-price widget_price_filter">
                        <div className="shop-filter-wrapper">

                        <div className="price_slider_wrapper">
                            <InputRange
                                step= {500}
                                maxValue={2500}
                                minValue={0}
                                value={value}
                                onChange={value => changeValue({ value })} />
                                <Button color='info' size='sm' outline className='mt-4 my-btna' onClick={e => priceSubmit(e)}>Set Price</Button>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="widget widget_layered_nav widget-layered-nav pgs_widget-layered-nav">
                    <h4 className="widget-title">Product Categories</h4>
                        <div className="pgs-widget-layered-nav-list-container has-scrollbar" style={{height: '215px'}}>
                         {props.categories.map((category, index) => (
                                <div className="form-check pgs-filter-checkbox" key={index}>
                                    <input type="checkbox" onClick={(e) => onClickCategoryFilter(e)} value={category.id} className="form-check-input" id={category.id} defaultChecked={toFilterCat.includes(category.id.toString())? true : false}/>
                                    <label className="form-check-label" htmlFor={category.id}>{category.name}</label>
                                </div> )
                            )}
                    </div>
                 </div>
            </div>
       )
}
export default SideFilter

