import React from 'react';
import { Button, Input, Form, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'store/actions'
import { toast } from 'react-toastify';
import History from '@history';
import './pagesStyle.css'


function PasswordReset(){
    const dispatch = useDispatch()
    const login = useSelector(({auth}) => {
        return auth.login.success ? true : false
    })
    const wait = useSelector(({app}) => app.Loading)
    if(login === true){
        History.push({
            pathname: '/'
        })
    }
    const onSendEmail = e => {
        e.preventDefault()
        let email = e.target.email ? e.target.email.value : null;
        if(email){
            console.log(email);
            dispatch(Actions.loadingToggle())
            dispatch(Actions.passwordRest(email))
        } else {
            toast.warn('Email not found')
        }
    }
    return(
        <div>
        <div className='reset-cont1st' style={{display: 'flex', height: '100vh'}}>
            <div className="reset-container">
                <div className="reset-1st">
                    <div className="reset-main">
                        <div className="reset-div">
                            <div className="img-div">
                                <i class="fa fa-unlock-alt" aria-hidden="true" style={{fontSize: '80px'}}></i>
                            </div>
                            <h6 className='reset-line'>RECOVER YOUR PASSWORD</h6>
                            <Form onSubmit={e => onSendEmail(e)} className='reset-form'>
                                <div className="reset-input-div">
                                    <div className="reset-input-2">
                                        <Input type="email" name="email" placeholder="Email *" className='reset-input' required/>
                                    </div>
                                </div>
                                {wait ? <Button className='reset-btn mt-2' disabled><Spinner style={{margin: 'auto', color: '#fff'}} /></Button>:
                                <Button color='primary' type='submit' className='reset-btn'>Send reset link</Button>}
                            </Form>
                            <div className='mt-4'><a href="/">Go back to login</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default PasswordReset;