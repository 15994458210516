/*
* Admin Site Product Edit Page
*/
import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Row, Col, FormGroup, Label, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ImageCrop from 'components/Admin/Product/ImageCrop'
import { v4 as uuidv4 } from 'uuid';
import * as Actions from 'store/actions'
import history from '@history'
import Domain from 'config.js'
import 'font-awesome/css/font-awesome.min.css';
import { toast } from 'react-toastify';
import MEditor from 'components/Admin/Product/MEditor';

function ProductEditDetail(props) {
    const dispatch = useDispatch()
    const {product} = props;
    const user = useSelector(({auth}) => {
        return auth.login.success ? auth.user : false
    })
    const Cat = useSelector(({admin}) => admin.Categories)
    const [pictures, setPictures] = useState([])
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [modal1, setModal1] = useState(false)
    const [delImg, setDelImg] = useState(null);
    const [newImage, setNewImage] = useState(props.product.pictures[0])
    const [formEdit, setFormEdit] = useState({
        name: product.name,
        brand: product.brand,
        salePrice: product.salePrice,
        disPrice: product.disPrice,
        buyPrice: product.buyPrice,
        category: product.category.id,
        stock: product.stock,
        SKU: product.SKU,
        description: product.description,
        details: product.details
    })
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
    const productslider = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1
      };
      if(user === false ){
          history.push({
              pathname: '/'
          })
      }
      const onDeleteInvoicePopup = (id) => {
        setDelImg(id)
        toggle1();
    }
      const toggle1 = () => {
        setModal1(!modal1)
      }
      const onChangeForm = (e) => {
        setFormEdit({
            ...formEdit,
            [e.target.name] : e.target.value
        })
      }
      const onDeleteImg =(e) => {
          e.preventDefault()
          if(product.pictures.length <= 2){
              toggle1()
              return toast.warn('minimun two pictures required')
          }
          let data = {
              imageId : delImg,
              productId : product.id
          }
          dispatch(Actions.deleteProductImage(data))
          toggle1()
      }
      const formSubmit = (e) => {
        e.preventDefault()
        if(pictures.length !== 0){
            if(pictures.length > 2){
                return toast.warn('Cannot upload more then 2 pictures')
            }
            const fd = new FormData();
                fd.append('id', props.product.id)
                fd.append('name', formEdit.name);
                fd.append('brand', formEdit.brand);
                fd.append('salePrice', formEdit.salePrice);
                fd.append('disPrice', formEdit.disPrice);
                fd.append('buyPrice', formEdit.buyPrice);
                fd.append('category_id', formEdit.category);
                fd.append('stock', formEdit.stock);
                fd.append('SKU', formEdit.SKU);
                fd.append('description', formEdit.description);
                fd.append('details', formEdit.details);
                for(let i= 0; i< pictures.length; i++){
                    fd.append('image', pictures[i].File, `proImages${i}`)
                }
            dispatch(Actions.adminProductUpdate(fd))
        } else {
            const edit = {
                id: product.id,
                name : formEdit.name,
                brand : formEdit.brand,
                salePrice : formEdit.salePrice,
                disPrice : formEdit.disPrice,
                buyPrice : formEdit.buyPrice,
                category_id : formEdit.category,
                stock: formEdit.stock,
                SKU: formEdit.SKU,
                description : formEdit.description,
                details : formEdit.details,
            }
            dispatch(Actions.adminProductUpdate(edit))
        }
    }

    const changePreviewImage = (image) => {
        setNewImage(image)
    }
    const cropedFile = (childFile) => {
        let data = {id: uuidv4(),File: childFile}
        setPictures([...pictures, data])
    }
    const delStateImg = res => {
        let selected = pictures.filter(prod => prod.id !== res)
        setPictures(selected);
    }
    const images=[];
    {product.pictures.map((pic)=>
        images.push(`${Domain}${pic.image}`)
    )}
        return (
            <section>
                <div className="product-content-top single-product single-product-edit">
                    <Row>
                        <div className="product-top-left col-xl-5 col-md-6">
                            <div className="product-top-left-inner">
                                <div className="ciyashop-product-images">
                                <div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
                                    <div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
                                    <Slider {...settings} className="ciyashop-product-gallery__wrapper popup-gallery">
                                        <div className="ciyashop-product-gallery__image">
                                                <img src={`${Domain}${newImage.image}`} className="img-fluid"  alt='holder-wer' width='100%'/>
                                                <div className='product-delete' style={{width: '50px', height: '50px', lineHeight: '57px', cursor: 'pointer'}}>
                                                <i onClick={() =>onDeleteInvoicePopup(newImage.id)} className="fa fa-trash-o fa-2x"></i></div>
                                        </div>
                                    </Slider>
                                    <div className="ciyashop-product-gallery_buttons_wrapper">
                                        <div className="ciyashop-product-gallery_button ciyashop-product-gallery_button-zoom popup-gallery" onClick={() => setIsOpen(true)} >
                                        <Link to="#" className="ciyashop-product-gallery_button-link-zoom">
                                            <i className="fa fa-arrows-alt" />
                                        </Link>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="ciyashop-product-thumbnails">
                                        <Slider {...productslider} className="ciyashop-product-thumbnails__wrapper">
                                            {product.pictures.map((pictureimage,index) =>
                                            <div className="ciyashop-product-thumbnail__image" key={index}>
                                                <Link  onClick={() => changePreviewImage(pictureimage)} to='#'>
                                                    <img src={`${Domain}${pictureimage.image}`}  className="img-fluid"  alt='holder-wer'/>
                                                </Link>
                                            </div>
                                            )}
                                        </Slider>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-top-right col-xl-7 col-md-6">
                        <div className="product-top-right-inner">
                            <form onSubmit={formSubmit}>
                                <FormGroup>
                                    <Row form>
                                        <Col md={6}>
                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Product Name</Label>
                                        <Input type="text" name = 'name' className="form-control" defaultValue={product.name} onChange= {e=> onChangeForm(e)} required />
                                        </Col>
                                        <Col md={6}>
                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Brand</Label>
                                        <Input type="text" name = 'brand' className="form-control" defaultValue={product.brand} onChange= {e=> onChangeForm(e)} required />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row form>
                                        <Col md={6}>
                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Sale Price</Label>
                                        <Input type="text" name = 'salePrice' className="form-control" defaultValue={product.salePrice} onChange= {e=> onChangeForm(e)} required />
                                        </Col>
                                        <Col md={6}>
                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Discounted Price</Label>
                                        <Input type="text" name = 'disPrice' className="form-control" defaultValue={product.disPrice} onChange= {e=> onChangeForm(e)} required />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row form>
                                        <Col md={6}>
                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Buy Price</Label>
                                        <Input type="text" name = 'buyPrice' className="form-control" defaultValue={product.buyPrice} onChange= {e=> onChangeForm(e)} required />
                                        </Col>
                                        <Col md={6}>
                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Category</Label>
                                        <select name="category" id="category"  className="form-control" onChange={e => onChangeForm(e)} >
                                            <option selected="selected" value = {product.category.id}>{product.category.name}</option>
                                            {Cat && Cat !==null ?
                                            Cat.map(cat => (
                                                    <option key = {cat.id} value = {cat.id}>{cat.name}</option>
                                            )) : <option value ='0'>wait</option>}
                                        </select>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row form>
                                        <Col md={6}>
                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Product Stock</Label>
                                        <Input type="text" name = 'stock' className="form-control" defaultValue={product.stock} onChange= {e=> onChangeForm(e)} required />
                                        </Col>
                                        <Col md={6}>
                                        <Label className="pb-2 mb-0 text-dark font-weight-bold">Product SKU</Label>
                                        <Input type="text" name = 'SKU' className="form-control" defaultValue={product.SKU} onChange= {e=> onChangeForm(e)} required />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="pb-2 mb-0 text-dark font-weight-bold">Short Description</Label>
                                    <Input  type="textarea" name = 'description' className="form-control" rows="2" defaultValue={product.description} onChange= {e=> onChangeForm(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="pb-2 mb-0 text-dark font-weight-bold">Details & Specification</Label>
                                    <Input  type="textarea" name = 'details' className="form-control" rows="2" defaultValue={product.details} onChange= {e=> onChangeForm(e)} />
                                </FormGroup>
                                <MEditor value={product.details} />
                                <button type='submit' href="#" className="btn btn-primary mb-2 mr-2"> Update </button>
                                <Link to="/admin/Product" className="btn btn-danger mb-2"> Cancel </Link>
                                <ImageCrop cropedFile={cropedFile} />
                                <div className='text-center mt-4'>
                                    <Row className= 'mt-4'>
                                    {pictures && pictures.length > 0 ?
                                    pictures.map(img => (
                                        <Col md={6} sm={6} xs={6} key={img.id} className='pb-4'>
                                            <div className='imgcrop-div'>
                                            <img src={URL.createObjectURL(img.File)} alt='crop-e' width='100%'/>
                                            <span className='del-mark' onClick={e => delStateImg(img.id)}>X</span>
                                            </div>
                                        </Col>
                                    )) : null
                                    }
                                    </Row>
                                </div>
                            </form>
                        </div>
                        </div>
                    </Row>
                    </div>
                    <div>
                        {isOpen && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                                onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % images.length)
                                }/>
                        )}
                    </div>
                    <Modal isOpen={modal1} toggle={toggle1} className="modal-delete modal-lg modal-dialog-centered">
                    <ModalHeader toggle={toggle1}></ModalHeader>
                    <ModalBody>
                      Are You Sure You Want To Delete This Image Permanently?
                    </ModalBody>
                    <ModalFooter className="justify-content-center pt-4">
                      <Link className="action-button" to="#" onClick={(e)=>onDeleteImg(e)}>Yes</Link>
                      <Link className="action-button no" to="#" onClick={toggle1}>No</Link>
                    </ModalFooter>
                  </Modal>
        </section>
        )
}
export default ProductEditDetail;

