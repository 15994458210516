/**
 *  Account Profile Edit
 */
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col,Container,Button } from 'reactstrap';
import Sitebar from './Sitebar';
import { Link } from 'react-router-dom';
import history from '@history'
import Loader from 'react-loader-spinner';
import * as Actions from 'store/actions'

function  AddressEdit() {
    const dispatch = useDispatch()
  const [Profile, setProfile] = useState(null)
  const ProfileData = useSelector(({auth}) => {
    return auth.login.success ? auth.user : false
  })
  useEffect(() => {
    window.scrollTo(0, 0)
      setProfile(ProfileData.data)
  },[ProfileData])
  if(ProfileData === false){
    history.push({
      pathname: '/'
    })
  }

  const onProfileFormSubmit = (e) => {
    e.preventDefault();
    delete Profile.photoURL
    delete Profile.email
    delete Profile.fullName
    delete Profile.phone
    Profile.id = ProfileData.id
    dispatch(Actions.updateCustomerProfile(Profile))
  }
  const handleChange = (e) => {
    setProfile({
      ...Profile,
      [e.target.name] : e.target.value
    })
  }
    return (
        <div>
          <div className="inner-intro">
            <Container>
                <Row className="intro-title align-items-center">
                    <Col md={6} className="text-left">
                        <div className="intro-title-inner">
                        <h1>My Account</h1>
                        </div>
                    </Col>
                    <Col md={6}  className="text-right">
                        <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                        <li className="home">
                            <span>
                            <Link className="bread-link bread-home" to="/">Home</Link>
                            </span>
                        </li>
                        <li><span>My Account</span></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            </div>
            <div className="section-ptb">
            <Container>
             <Row>
              <Sitebar />
              <Col lg={9} className="mt-4 mt-lg-0">
                {Profile === null ?
                <div className= 'custom-loader'>
                  <Loader type="Puff" color="#04d39f" height={100} width={100}/>
                </div> :
                <Row>
                <Col lg={12}>
                  <div className="woocommerce-Address woocommerce-Address-edit">
                    <div className="woocommerce-Address-title">
                      <h5 className="mb-0">Profile Information Edit </h5>
                    </div>
                    <div className="woocommerce-Address-info mt-4">
                    <form onSubmit={e => onProfileFormSubmit(e)}>
                    <div className="form-group">
                          <label>Street Adress</label>
                          <input type="text" className="form-control" name="address" value={Profile.address}  onChange={e => handleChange(e)} placeholder="Street Adress" />
                        </div>
                        <div className="form-group">
                          <label>City</label>
                          <input type="text" className="form-control" name="city" placeholder="City" value={Profile.city} onChange={e => handleChange(e)}></input>
                        </div>
                        <Button type="submit" className="btn btn-primary">Save</Button>
                    </form>
                    </div>
                  </div>
                </Col>
                </Row>}
              </Col>
            </Row>
            </Container>
            </div>
        </div>
        )
}
export default AddressEdit;
