import Domain from 'config.js'
import axios from 'axios';
import { toast } from 'react-toastify';
import history from '@history'
export const GET_PRODUCTS = '[APP] GET_PRODUCTS';
export const GET_PRODUCTDETAIL = '[APP] GET_PRODUCTDETAIL';
export const GET_PRODUCTCOMMENTS = '[APP] GET_PRODUCTCOMMENTS';
export const GET_ADMIN_PRODUCTS = '[ADMIN] GET_ADMIN_PRODUCTS';
export const ADMIN_PRODUCT_LOADING = '[ADMIN] ADMIN_PRODUCT_LOADING';
export const ADMIN_PRODUCTDETAIL = '[ADMIN] ADMIN_PRODUCTDETAIL';
export const ADMIN_PRODDETAIL_LOADING = '[ADMIN] ADMIN_PRODDETAIL_LOADING';
export const ADMIN_PRODUCT_UPDATE = '[ADMIN] ADMIN_PRODUCT_UPDATE';
export const PRODUCT_IMAGE_DELETE = '[ADMIN] PRODUCT_IMAGE_DELETE';
export const ADMIN_PRODUCT_DELETE = '[ADMIN] ADMIN_PRODUCT_DELETE';
export const ADMIN_ADD_PRODUCT = '[ADMIN] ADMIN_ADD_PRODUCT';
export const GENERAL_LOADING = '[ADMIN] GENERAL_LOADING';

export function getProducts(page) {
    const request = axios.post(`${Domain}/buyer/getProducts/latest`, {page, limit :12});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PRODUCTS,
                payload: response.data.result
            })
        }
        ).catch(error => console.log(error))
    )
}
export function getProductsByCat(catId, price, SearchValue) {
    console.log(catId, price, SearchValue);
    const request = axios.post(`${Domain}/buyer/getProducts/category`, {catId, price, SearchValue});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PRODUCTS,
                payload: response.data.result
            })
        }
        ).catch(error => console.log(error))
    )
}

export function getProductDetail(id) {
    const request = axios.get(`${Domain}/buyer/productDetails`, {
        params : {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PRODUCTDETAIL,
                payload: response.data.product
            })
        }
        )
    )
}

export function getProductComments(id) {
    const request = axios.get(`${Domain}/buyer/getProductComments`, {
        params : {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_PRODUCTCOMMENTS,
                payload: response.data.comments
            })
        }
        )
    )
}

export function adminProductLoading(val){
    return (dispatch) => {
        return dispatch({
            type: ADMIN_PRODUCT_LOADING,
            payload : val
        })
    }
}

export function getAdminProducts(page) {
    const request = axios.post(`${Domain}/admin/getAdminProducts/latest`, {page});

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_ADMIN_PRODUCTS,
                payload: response.data.result
            })
        }
        ).catch(error => console.log(error))
    )
}
export function adminProdByCat(data) {
    const request = axios.post(`${Domain}/admin/getAdminProducts/category`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: GET_ADMIN_PRODUCTS,
                payload: response.data.result
            })
        }
        ).catch(error => console.log(error))
    )
}

export function adminProdDetailLoading(val){
    return (dispatch) => {
        return dispatch({
            type: ADMIN_PRODDETAIL_LOADING,
            payload : val
        })
    }
}

export function adminProductDetail(id) {
    const request = axios.get(`${Domain}/admin/adminProductDetail`, {
        params : {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADMIN_PRODUCTDETAIL,
                payload: response.data.product
            })
        })
    )
}
export function adminProductUpdate(data) {
    const request = axios.put(`${Domain}/admin/updateProduct`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADMIN_PRODUCT_UPDATE
            })
        })
        .then(() => toast.success('Product Updated'))
        .then(() => history.push({pathname: '/admin/Product'}))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot update product')
        })
    )
}
export function deleteProductImage(data) {
    const request = axios.post(`${Domain}/admin/deleteProductImage`, data);

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: PRODUCT_IMAGE_DELETE
            })
        })
        .then(() => dispatch(adminProductDetail(data.productId)))
        .then(() => toast.success('Product image deleted'))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot delete product image')
        })
    )
}
export function deleteProduct(id) {
    const request = axios.delete(`${Domain}/admin/deleteProduct`, {
        params: {id}
    });

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADMIN_PRODUCT_DELETE
            })
        })
        .then(() => dispatch(getAdminProducts(0)))
        .then(() => toast.success('Product deleted'))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot delete product')
        })
    )
}

export function addNewProduct(data){
    const request = axios.post(`${Domain}/admin/addProduct`, data)

    return (dispatch) => (
        request.then((response) => {
            return dispatch({
                type: ADMIN_ADD_PRODUCT
            })
        })
        .then(() => toast.success('Product Added'))
        .then(() => history.push({pathname: '/admin/Product'}))
        .then(() => dispatch(adminGeneralLoading(false)))
        .catch(error => {
            console.log(error);
            toast.warn('Cannot add product')
        })
    )
}

export function adminGeneralLoading(val){
    return (dispatch) => {
        return dispatch({
            type: GENERAL_LOADING,
            payload : val
        })
    }
}