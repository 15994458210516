/**
 *  Success Screen
 */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col,Container } from 'reactstrap';
import Domain from 'config.js'
import moment from 'moment';

function SuccessScreen() {
    const [cartItem, setCartItem] = useState(null)
    useEffect(() => {
      window.scrollTo(0, 0)
      setCartItem(JSON.parse(localStorage.getItem('FinalCheckoutCartItems')))
      localStorage.removeItem('FinalCheckoutCartItems')
    },[])
    const date = new Date();
    console.log(cartItem);
   return (
    <div>
    <div className="inner-intro">
      <Container>
          <Row className="intro-title align-items-center">
              <Col md={6} className="text-left">
                  <div className="intro-title-inner">
                  <h1>My Account</h1>
                  </div>
              </Col>
              <Col md={6}  className="text-right">
                  <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                  <li className="home">
                      <span>
                      <Link className="bread-link bread-home" to="/">Home</Link>
                      </span>
                  </li>
                  <li><span>My Account</span></li>
                  </ul>
              </Col>
          </Row>
      </Container>
      </div>
        <div className="section-ptb">
            {cartItem && cartItem!==null?
            <Container>
            <Row className="justify-content-center">
              <Col lg={7}>
                <div className="success-screen">
                  <div className="thank-you text-center">
                    <i className="fa fa-check-circle-o"></i>
                    <h1 className="text-white">Thank You</h1>
                    <span>Success! We received your payment. Your order will be processed soon.</span>
                    <strong className="text-white">Transaction ID:637686G154T154485</strong>
                  </div>
                  <div className="delivery p-4 p-md-5 bg-light text-center">
                    <span className="h5">Expected Date Of Delivery</span>
                      <h2 className="mb-0 mt-2">{moment().add(10, 'days').calendar()}</h2>
                  </div>
                  <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3">
                  <Row>
                    <Col lg={6}>
                     <h6>Ship To</h6>
                      <ul className="list-unstyled mb-0">
                        <li>{cartItem.fullName}</li>
                        <li>{cartItem.phone}</li>
                        <li>{cartItem.deliveryAddress}</li>
                        <li>{cartItem.paymentMethod}</li>
                      </ul>
                    </Col>
                    <Col lg={6} className="text-lg-right mt-4 mt-lg-0">
                      <h6>Summary</h6>
                      <ul className="list-unstyled mb-0">
                        <li><span>Order ID:</span> <strong>7582654455365</strong></li>
                        <li><span>Order Date:</span><strong>{date.toLocaleString('en-us', { month: 'long' })}{new Date(). getDay()},{new Date().getFullYear()}</strong></li>
                        <li><span>Order Total:</span> <strong>${parseFloat(parseFloat(cartItem.products.reduce((fr, CartItem) => fr + (CartItem.quantity * CartItem.rate), 0)) + parseFloat((cartItem.shippingCost !== undefined) ? cartItem.shippingCost.toFixed(2) : 0)).toFixed(2)} </strong></li>
                      </ul>
                    </Col>
                  </Row>
                  </div>
                  {(cartItem !== null && cartItem.products.length > 0) ?
                  <div className="ordered-detail">
                    <h5 className="mb-4">Your Ordered Details</h5>
                    <div className="table-responsive">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th className="text-center"><span>Image</span></th>
                                <th className="text-center"><span>Product Name</span></th>
                                <th className="text-center"><span>Quantity</span></th>
                                <th className="text-center"><span>Price</span></th>
                            </tr>
                        </thead>
                      <tbody>
                      {cartItem.products.map((item, index) => (
                        <tr className="ordered-item" key ={index}>
                          <td className="ordered-image">
                            <img src={`${Domain}${item.ProductImage}`} alt="product-img" className="img-fluid" />
                          </td>
                          <td  className="ordered-name">
                            <span>{item.ProductName}</span>
                          </td>
                          <td className="ordered-quantity">
                            <span>{item.quantity}</span>
                          </td>
                          <td className="ordered-price">
                           <span>${item.rate * item.quantity}</span>
                          </td>
                        </tr>
                       ))}
                      </tbody>
                    </table>
                    </div>
                    <div className="table-responsive">
                    <table className="table total-table table-borderless mt-4 mb-0">
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td className="text-right">${parseFloat(parseFloat(cartItem.products.reduce((fr, CartItem) => fr + (CartItem.quantity * CartItem.rate), 0))).toFixed(2)}</td>
                        </tr>
                        <tr>
                        <td>Shipping</td>
                          <td className="text-right">${cartItem.shippingCost.toFixed(2)}</td>
                        </tr>
                        <tr className="border-top">
                        <td><strong className="h5">Total</strong></td>
                          <td className="text-right h5"><strong>${parseFloat(parseFloat(cartItem.products.reduce((fr, CartItem) => fr + (CartItem.quantity * CartItem.rate), 0)) + parseFloat((cartItem.shippingCost !== undefined) ? cartItem.shippingCost.toFixed(2) : 0)).toFixed(2)}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </div>
                  :
                  <div>
                    No Items found
                  </div>
                  }
                  <div className="d-sm-flex px-4 pb-4 px-md-5 pb-md-5">
                    <Link className="button ml-auto" to="/">Go to home</Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>:
            <div className='text-center'>
                <h2>No Items found</h2>
                <div className="px-4 pb-4 px-md-5 pb-md-5">
                    <Link className="button ml-auto" to="/">Go to home</Link>
                </div>
            </div>
        }
        </div>
    </div>
        )
}
export default SuccessScreen;
