/**
 *  Account Page Order History
 */
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row,Container,Col ,Modal, ModalBody, ModalHeader  } from 'reactstrap';
import Sitebar from './Sitebar';
import * as AppActions from 'store/actions'
import { Link } from 'react-router-dom';
import history from '@history'
import * as moment from 'moment';
import Domain from 'config.js'
import Loader from 'react-loader-spinner';

function OrderHistory(){
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [orderHistory, setOrderHistory] = useState(null)
  const [viewOrder, setViewOrder] = useState(null)
  const user = useSelector(({auth}) => {
    return auth.login.success ? auth.user : false
  })
  const orders = useSelector(({app}) => app.Orders)
  useEffect(() => {
    window.scrollTo(0,0);
    if(user){
        dispatch(AppActions.getCustomerOrders(user.id))
    }
  },[user, dispatch])
  useEffect(() => {
    setOrderHistory(orders.rows)
  },[orders])
  const toggle = () => {
    setModal(!modal)
  }
  const onViewOrder = (data) =>{
    setViewOrder(data)
      toggle()
  }
  const cancelOrder = (e,orderId, customer_id) => {
    e.preventDefault()
    console.log(orderId, customer_id);
    let data = {
      orderId,
      customer_id
    }
    dispatch(AppActions.cancelOrder(data))
  }
  if(user === false){
    history.push({
      pathname: '/'
    })
  }
  return (
    <div>
    <div className="inner-intro">
      <Container>
          <Row className="intro-title align-items-center">
              <Col md={6} className="text-left">
                  <div className="intro-title-inner">
                  <h1>My Account</h1>
                  </div>
              </Col>
              <Col md={6}  className="text-right">
                  <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                  <li className="home">
                      <span>
                      <Link className="bread-link bread-home" to="/">Home</Link>
                      </span>
                  </li>
                  <li><span>My Account</span></li>
                  </ul>
              </Col>
          </Row>
      </Container>
      </div>
    <div className="section-ptb">
    <Container>
          <Row>
            <Sitebar />
          <Col lg={9} className="mt-4 mt-lg-0">
            <Row>
            <Col lg={12}>
            {orderHistory === null ?
              <div className='custom-loader'>
                <Loader type="Puff" color="#04d39f" height={100} width={100} />
              </div>:
              orderHistory.length === 0 ?
              <div className="text-center">
                <h4>No orders yet.</h4>
              </div>:
              <div className="table-responsive">
                <table className="table orderhistory-table mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Order</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Total</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {orderHistory.map((Ordervalue) =>
                          <tr key={Ordervalue.id}>
                          <td>#pk-{Ordervalue.id}</td>
                          <td>{moment(Ordervalue.createdAt).format('MMM DD YYYY h:mm A')}</td>
                          <td>{Ordervalue.orderStatus}</td>
                          <td>${Ordervalue.totalPrice}</td>
                          <td><Link className="action-button" onClick={() => onViewOrder(Ordervalue)}  to="#">View</Link></td>
                          </tr>
                  )}
                  </tbody>
                </table>
              </div>}
                </Col>
              </Row>
                {/* modal-view */}
                <Modal isOpen={modal} toggle={toggle} className="modal-view modal-lg modal-dialog-centered">
                <ModalHeader toggle={toggle}></ModalHeader>
                {viewOrder !== null ?
                <ModalBody>
                  <div className="success-screen">
                  <div className="thank-you text-center">
                    <i className="fa fa-check-circle-o"></i>
                    <h1 className="text-white">Thank You</h1>
                    <span>Success! We received your Order. Your order will be processed soon.</span>
                    <strong className="text-white">Transaction ID: #pk-{viewOrder.id}</strong>
                  </div>
                  <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3">
                  <Row>
                    <Col lg={6}>
                    <h6>Ship To</h6>
                      <ul className="list-unstyled mb-0">
                        <li>{viewOrder.fullName}</li>
                        <li>{viewOrder.phone}</li>
                        <li>{viewOrder.email}</li>
                        <li>{viewOrder.deliveryAddress}, {viewOrder.city}</li>
                      </ul>
                    </Col>
                    <Col lg={6} className="text-lg-right mt-4 mt-lg-0">
                      <h6>Summary</h6>
                      <ul className="list-unstyled mb-0">
                        <li><span>Order ID:</span> <strong>#pk-{viewOrder.id}</strong></li>
                        <li><span>Order Date:</span> <strong>{moment(viewOrder.createdAt).format('MMM DD YYYY h:mm A')}</strong></li>
                        <li><span>Order Total:</span> <strong>${viewOrder.totalPrice}</strong></li>
                        {viewOrder.orderStatus === "Pending" ?
                        <Link to= '#' onClick={e => cancelOrder(e, viewOrder.id, user.id)} style={{color: 'red'}}>Cancel Order</Link>:
                        <></>}
                      </ul>
                    </Col>
                  </Row>
                  </div>
                  <div className="ordered-detail">
                    <h5 className="mb-4">Your Ordered Details</h5>
                    <div className="table-responsive">
                    <table className="table mb-0">
                    <thead className="thead-light">
                        <tr>
                          <th scope="col">Picture</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Price</th>
                          <th scope="col">Review</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewOrder.productOrder.map(pros => (
                          <tr className="ordered-item" key={pros.id}>
                            <td className="ordered-image">
                                <img alt="product-img"  src={`${Domain}${pros.productImage}`} className="img-fluid" />
                            </td>
                            <td  className="ordered-name">
                              <span>{pros.name}</span>
                            </td>
                            <td className="ordered-quantity">
                              <span>{pros.quantity}</span>
                            </td>
                            <td className="ordered-price">
                            <span>${pros.rate}</span>
                            </td>
                            {viewOrder.orderStatus === 'Delivered' ? 
                            pros.rating_id ===null ?
                            <td>
                            <Link className="action-button" to={{pathname : '/product/review', state: {productId: pros.product_id, orderId: viewOrder.id, productOrder_id: pros.id}}}>Review</Link>
                            </td>:
                            <td>
                              <span>Submitted</span>
                            </td>:
                            <td>
                                <span>Pending</span>
                            </td>}
                        </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                    <div className="table-responsive">
                    <table className="table total-table table-borderless mt-4 mb-0">
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td className="text-right">${viewOrder.totalPrice}</td>
                        </tr>
                        <tr>
                        <td>Shipping</td>
                        <td className="text-right">
                          ${viewOrder.shippingCost}
                          </td>
                        </tr>
                        <tr className="border-top">
                        <td><strong className="h5">Total</strong></td>
                          <td className="text-right h5"><strong>${JSON.parse(viewOrder.totalPrice) + JSON.parse(viewOrder.shippingCost)}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
                </ModalBody>
                :
                  null
                }
              </Modal>
          </Col>
        </Row>
        </Container>
        </div>
      </div>
    )

    }
export default OrderHistory;
