import * as Actions from '../actions';
import * as moment from 'moment';

const initialState = {
    GLoading : false,
    Categories : null,
    Customers : {
        rows : null,
        totalCustomers : null,
    },
    AdminProdDetail: {
        Loading: false,
        details: null,
    },
    productComments:null,
    Orders : {
        Loading: false,
        rows : null,
        totalOrders : null,
    },
    AdminProducts: {
        Loading: false,
        rows: null,
        totalProducts: null
    },
    OrderCountGraph: {
        Loading: false,
        day: null,
        week: null,
        month : null
    }
};

const adminReducer = function (state = initialState, action){
    switch ( action.type ){
        case Actions.GENERAL_LOADING:
            {
                if(action.payload !== null && action.payload !== undefined){
                    let res = action.payload
                    return{
                        ...state,
                        GLoading: res
                        }
                    } else return state;
            }
        case Actions.ADMIN_CATEGORIES:
            {
                let resFormattedMapped = []
                if (action.payload) {
                    let res = action.payload
                    resFormattedMapped = res.map(cat => (
                        {
                            id: cat.id,
                            name: cat.name,
                            created_at: moment(cat.datetime).format('MMM DD YYYY h:mm A')
                        }
                    ))
                }
                return {
                    ...state,
                    Categories: resFormattedMapped,
                };
            }
            case Actions.GET_ORDERS_ADMIN:
                {
                    let totalOrders
                    let rows = []
                    if(action.payload){
                        let res = action.payload
                        totalOrders = res.count
                        rows = res.rows.map(idx => (
                            {
                                id: idx.id,
                                fullName: idx.fullName,
                                phone: idx.phone,
                                email: idx.email,
                                totalPrice: idx.totalPrice,
                                shippingCost: idx.shippingCost,
                                deliveryAddress: idx.deliveryAddress,
                                city: idx.city,
                                paymentMethod: idx.paymentMethod,
                                orderStatus: idx.orderStatus,
                                productOrder : idx.productOrder.map(pdx => ({
                                    id : pdx.id,
                                    product_id : pdx.productorderProduct.id,
                                    name : pdx.productorderProduct.name,
                                    quantity : pdx.quantity,
                                    rate : pdx.rate,
                                    productImage: pdx.productorderProduct.productimages[0].image
                                })),
                                createdAt: moment(idx.createdAt).format('MMM DD YYYY h:mm A')
                            }
                        ))
                    }
                    return {
                        ...state,
                        Orders: {totalOrders, rows, Loading: false}
                    }
                }
            case Actions.ADMIN_ORDER_LOADING:
                {
                    if(action.payload){
                        return{
                            ...state,
                            Orders: {
                                ...state.Orders,
                                Loading: action.payload
                            }
                        }
                    } else return state;
                }
            case Actions.GET_ADMIN_PRODUCTS:
                {
                    let resFormattedMapped = []
                    let totalProducts
                    if (action.payload) {
                        let res = action.payload
                        totalProducts = res.count
                        resFormattedMapped = res.rows.map(inv => (
                            {
                                id: inv.id,
                                name: inv.name,
                                salePrice: inv.salePrice,
                                disPrice: inv.disPrice,
                                rating: inv.ratings.toFixed(1),
                                category : inv.productCategory.name,
                                pictures: inv.productimages.map(pix => (
                                    pix.image
                                )),
                            }
                        ))
                    }
                    return {
                        ...state,
                        AdminProducts: {Loading: false, rows: resFormattedMapped, totalProducts},
                    };
                }
            case Actions.ADMIN_PRODUCT_LOADING:
                {
                    if(action.payload){
                        return{
                            ...state,
                            AdminProducts: {
                                ...state.AdminProducts,
                                Loading: action.payload
                            }
                        }
                    } else return state;
                }
            case Actions.ADMIN_PRODUCTDETAIL:
                {
                    let resFormattedMapped = {}
                    if (action.payload) {
                        let product = action.payload
                        resFormattedMapped = {
                                id: product.id,
                                name: product.name,
                                brand : product.brand,
                                salePrice: product.salePrice,
                                disPrice: product.disPrice,
                                buyPrice: product.buyPrice,
                                category: {
                                    id: product.productCategory.id,
                                    name: product.productCategory.name,
                                },
                                stock: product.stock,
                                SKU: product.SKU,
                                description: product.description,
                                details: product.details,
                                pictures: product.productimages.map(pix => (
                                    {id: pix.id, image: pix.image}
                                )),
                                createdAt: moment(product.createdAt).format('MMM DD YYYY h:mm A'),
                            }
                    }
                    return {
                        ...state,
                        AdminProdDetail: {
                            Loading: false,
                            details: resFormattedMapped
                        },
                    };
                }
            case Actions.ADMIN_PRODDETAIL_LOADING:
                {
                    if(action.payload){
                        return{
                            ...state,
                            AdminProdDetail: {
                                ...state.AdminProdDetail,
                                Loading: action.payload
                            }
                        }
                    } else return state;
                }
            case Actions.LOADING_COUNT_GRAPH:
                {
                    if(action.payload !==null ||action.payload !== undefined){
                        return {
                            ...state,
                            OrderCountGraph: {
                                ...state.OrderCountGraph,
                                Loading: action.payload
                            }
                        }

                    } else return state
                }
            case Actions.ORDERS_COUNT_GRAPH:
                {
                    let OrderCountGraph = {Loading: false, day: [], week: [], month: []}
                    if (action.payload) {
                        let res = action.payload
                        OrderCountGraph.day = res[0].map(exp => (
                            {x : new Date(moment(exp.createdAt).format('YYYY, MM, DD')), y: exp.totalCount}
                        ))
                        OrderCountGraph.week = res[1].map(exp => (
                            {x : new Date(moment(exp.createdAt).format('YYYY, MM, DD')), y: exp.totalCount}
                        ))
                        OrderCountGraph.month = res[2].map(exp => (
                            {x : new Date(moment(exp.createdAt).format('YYYY, MM')), y: exp.totalCount}
                        ))
                    }
                    return {
                        ...state,
                        OrderCountGraph
                    };
                }
        default:
            {
                return state;
            }
    }
}

export default adminReducer;