
/**
 * Admin List Product Display
 **/
import React , {Component} from 'react';
import { Col,Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {Link} from 'react-router-dom';
import Domain from 'config.js'

class AdminproductList extends Component {
    constructor(props){
        super(props);
        this.toggle1 = this.toggle1.bind(this);
        this.state = {
            open: false,
            modal1: false,
            stock: 'InStock',
            quantity: 1,
            image: ''
        }
    }

    toggle1() {
        this.setState(prevState => ({
          modal1: !prevState.modal1
        }));
    }
    onDeleteInvoicePopup()
    {
        this.toggle1();
    }
    render() {
    const {product,deleteproduct} = this.props;
    return (
        <Col key={1} sm={6} lg={3}>
                <div className="product product_tag-black product-hover-style-default product-hover-button-style-light product_title_type-single_line product_icon_type-line-icon">
                <div className="product-inner element-hovered">
                <div className="product-thumbnail">
                    <div className="product-thumbnail-inner">
                    <Link to="#">
                        {product.pictures[0] ?
                            <div className="product-thumbnail-main">
                                <img src={`${Domain}${product.pictures[0]}`} className="img-fluid" alt='holder-wer' />
                            </div>
                        :
                            null
                        }
                        {product.pictures[1]  ?
                            <div className="product-thumbnail-swap">
                                    <img src={`${Domain}${product.pictures[1]}`} className="img-fluid" alt='holder-wer'/>
                            </div>
                            :
                            null
                        }
                    </Link>
                    </div>
                    <div className="product-action product-action-quick-view">
                        <Link to={`/admin/Product-edit/${product.id}`} className="open-edit-view"><i className="fa fa-pencil-square-o"></i></Link>
                        <Link to="#" className="product-delete" onClick={() => this.onDeleteInvoicePopup()} ><i className="fa fa-trash-o"></i></Link>
                    </div>
                    <div className="product-actions">
                    </div>
                </div>
                <div className="product-info">
                    <span className="ciyashop-product-category">
                        {product.category}
                    </span>
                    {product.name ?
                    <h3 className="product-name">
                        <Link to="#">{product.name}</Link>
                    </h3>
                    : null }
                    {product.salePrice ?
                        <span className="price">
                        <ins>
                            <span className="price-amount amount">
                                <span className="currency-symbol">$</span>{product.disPrice}
                            </span>
                            <span className="price-amount" style= {{marginLeft: '5px', textDecoration: 'line-through'}}>
                                <span className="currency-symbol">$</span>{product.salePrice}
                            </span>
                        </ins>
                        </span>
                    : null }
                </div>
                </div>
            </div>
            {/* modal-delete */}
            <Modal isOpen={this.state.modal1} toggle={this.toggle1} className="modal-delete modal-lg modal-dialog-centered">
                <ModalHeader toggle={this.toggle1}  ></ModalHeader>
                <ModalBody>
                        Are You Sure You Want To Delete This Product ?
                </ModalBody>
                <ModalFooter className="justify-content-center pt-4" >
                    <Link className="action-button"  onClick={
                        this.toggle1,
                        deleteproduct
                        } to="#">Yes</Link>
                    <Link className="action-button no"  onClick={this.toggle1} to="#">No</Link>
                </ModalFooter>
            </Modal>
        </Col>
        )
    }
}

export default AdminproductList;
