/**
 *  Shop Product Detail Page
 */
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PostDetail from '../../templates/post-detail';
import { Link } from 'react-router-dom';
import { Row, Col,Container,Nav, NavItem, NavLink,TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import Loader from 'react-loader-spinner';
import * as Actions from 'store/actions'
import * as moment from 'moment';
import './detail.css'

function ProductDetail(props) {
    const dispatch = useDispatch();
    const productId = parseInt(props.match.params.id);
    const [activeTab, setActiveTab] = useState('1');
    const [currentProduct, setCurrentProduct] = useState(null);
    const [comments, setComments] = useState(null)
    const [loading, setLoading] = useState(true);
    const TheProduct = useSelector(({app}) => app.productDetail);
    const TheComments = useSelector(({app}) => app.productComments);
    useEffect(() => {
        if(productId){
            window.scrollTo(0,0)
            dispatch(Actions.getProductDetail(productId))
            dispatch(Actions.getProductComments(productId))
        }
    },[productId, dispatch])
    useEffect(() => {
        if(TheProduct){
            setCurrentProduct(TheProduct)
            setLoading(false)
        }
    },[TheProduct])
    useEffect(() => {
        if(TheComments){
            setComments(TheComments)
        }
    },[TheComments])
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }}
    const rating = (productrat) => {
        let rat=[];
        let i = 1;
        while (i <= 5) {
                if(i<=productrat)
                {
                    rat.push(<i key={i} className="fa fa-star" />);
                }
                else
                {
                    rat.push(<i className="fa fa-star-o" />);
                }
                i += 1;
        }
        return rat;
        }
    if(!currentProduct || currentProduct === null){
        return(
        <div id="preloader">
            <Loader type="Puff" color="#04d39f" height={100} width={100} />
        </div>
        )}
    return (
        <div>
            {!loading ?
                <div className="site-content">
                    <div className="inner-intro">
                    <Container>
                        <Row className="intro-title align-items-center">
                            <div className="col-12">
                                <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                                <li className="home">
                                    <span>
                                    <Link className="bread-link bread-home" to="/">Home</Link>
                                    </span>
                                </li>
                                    <li><span>{currentProduct.category}</span></li>
                                     <li><span>{currentProduct.name}</span></li>
                                </ul>
                            </div>
                        </Row>
                    </Container>
                </div>
                <div className="content-wrapper section-ptb">
                    <Container>
                        <PostDetail key={currentProduct.id} product={currentProduct} tabid={activeTab} />
                        <div className="product-content-bottom">
                            <Nav tabs>
                                <NavItem active>
                                    <NavLink   className={classnames({ active: activeTab === '1' })}  onClick={() => { toggle('1'); }}>Description</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink  className={classnames({ active: activeTab === '2' })}  onClick={() => { toggle('2'); }}>Reviews</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                        <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="home-tab">
                                                <div dangerouslySetInnerHTML={{__html: currentProduct.details}} />
                                                <div className="product-info-box border-top border-bottom mt-5  pt-4 pt-lg-0 pb-2 pb-sm-0">
                                                    <Row>
                                                    <Col sm={6} md={4}>
                                                        <div className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font  ciyashop_info_box_2-icon-size-md  ciyashop_info_box_2-icon_position-left icon-left-spacing">
                                                        <div className="ciyashop_info_box_2-inner clearfix align-items-center">
                                                            <div className="ciyashop_info_box_2-icon">
                                                            <div className="ciyashop_info_box_2-icon-wrap">
                                                                <div className="ciyashop_info_box_2-icon-outer">
                                                                <div className="ciyashop_info_box_2-icon-inner">
                                                                    <i className="glyph-icon pgsicon-ecommerce-delivery-truck-1" /> </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div className="ciyashop_info_box_2-content">
                                                            <div className="ciyashop_info_box_2-content-wrap">
                                                                <div className="ciyashop_info_box_2-content-inner">
                                                                <h6 className="ciyashop_info_box_2-title inline_hover">Free shipping </h6>
                                                                <div className="ciyashop_info_box_2-content">
                                                                    <p>Free Shipping on orders $199.</p>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} md={4} className="py-3 py-md-0">
                                                        <div className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font  ciyashop_info_box_2-icon-size-md  ciyashop_info_box_2-icon_position-left icon-left-spacing">
                                                        <div className="ciyashop_info_box_2-inner clearfix align-items-center">
                                                            <div className="ciyashop_info_box_2-icon">
                                                            <div className="ciyashop_info_box_2-icon-wrap">
                                                                <div className="ciyashop_info_box_2-icon-outer">
                                                                <div className="ciyashop_info_box_2-icon-inner">
                                                                    <i className="glyph-icon pgsicon-ecommerce-headphones-1" /> </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div className="ciyashop_info_box_2-content">
                                                            <div className="ciyashop_info_box_2-content-wrap">
                                                                <div className="ciyashop_info_box_2-content-inner">
                                                                <h6 className="ciyashop_info_box_2-title inline_hover">
                                                                    24/7 Support: </h6>
                                                                <div className="ciyashop_info_box_2-content">
                                                                    <p>Online and phone support 24 / 7</p>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6} md={4}>
                                                        <div className="ciyashop_info_box_2 ciyashop_info_box_2-layout-style_2 ciyashop_info_box_2-content_alignment-left ciyashop_info_box_2-with-icon ciyashop_info_box_2-icon-source-font  ciyashop_info_box_2-icon-size-md  ciyashop_info_box_2-icon_position-left icon-left-spacing">
                                                        <div className="ciyashop_info_box_2-inner clearfix align-items-center">
                                                            <div className="ciyashop_info_box_2-icon">
                                                            <div className="ciyashop_info_box_2-icon-wrap">
                                                                <div className="ciyashop_info_box_2-icon-outer">
                                                                <div className="ciyashop_info_box_2-icon-inner">
                                                                    <i className="glyph-icon pgsicon-ecommerce-reload" /> </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                            <div className="ciyashop_info_box_2-content">
                                                            <div className="ciyashop_info_box_2-content-wrap">
                                                                <div className="ciyashop_info_box_2-content-inner">
                                                                <h6 className="ciyashop_info_box_2-title inline_hover">30 Days Return </h6>
                                                                <div className="ciyashop_info_box_2-content">
                                                                    <p>30 days money back guarantee. </p>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </Col>
                                                    </Row>
                                                </div>
                                                </div>

                                            </div>

                                </TabPane>
                                <TabPane tabId="2">
                                <div className="product-reviews">
                                    <div className="review-heading"><h4>Product Reviews</h4></div>
                                    {comments === null ?
                                    <div className='custom-loader'>
                                        <Loader type="Puff" color="#04d39f" height={100} width={100} />
                                    </div>
                                    :
                                    comments.length === 0 ?
                                    <div className="text-center mt-6">
                                        <h2>No Reviews Yet!</h2>
                                    </div>:
                                    comments.map(com => (
                                        <div key={com.id} className='comment-div'>
                                            <div className='comment-date'>{moment(com.createdAt).startOf('hour').fromNow()}</div>
                                            <div className="comments">{rating(com.rating)}</div>
                                            <div>by {com.name}</div>
                                            <div className='comment12'>{com.comment}</div>
                                        </div>
                                    )) 
                                    }
                                </div>
                                </TabPane>
                            </TabContent>
                            </div>
                    </Container>
                </div>
                </div>
         :
            <div id="preloader">
                <Loader type="Puff" color="#04d39f" height={100} width={100} />
            </div>
        }
        </div>
    )
}
export default ProductDetail;