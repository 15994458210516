/**
 * Default Home Page
 */
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HomeSlider from '../../widgets/HomSlider.js';
import EndOfSeason from '../../widgets/EndOfSeason.js';
import AboutBanner from '../../widgets/AboutBanner.js';
import TopSellingProduct from '../../widgets/TopSellingProduct.js';
import { Row, Col,Container } from 'reactstrap';
import Loader from 'react-loader-spinner';
import * as AppActions from 'store/actions'

function HomePage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(AppActions.getProducts());
    },[dispatch])
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState(null);
    const TopProducts = useSelector(({app}) => app.Products.rows)
    useEffect(() => {
       window.scrollTo(0, 0)
    if(TopProducts){
        setProducts(TopProducts)
        setLoading(false)
    }
    }, [TopProducts])
    return (
         <div>
            <HomeSlider />
            <div id="content" className="site-content" tabIndex={-1}>
            <div className="content-wrapper content-wrapper-vc-enabled">
               <div id="primary" className="content-area">
                  <main id="main" className="site-main">
                     <article id="post-13069" className="post-13069 page type-page status-publish hentry">
                        <div className="entry-content">
                           <Container>
                                 <EndOfSeason />
                           </Container>
                           <Container>
                              <Row className="mb-0 mb-sm-3 mb-lg-5">
                              <Col sm={12}>
                                 <Row mb={3} className="justify-content-center">
                                    <Col sm={10} lg={6} className="text-center">
                                       <div className="section-title">
                                          <h2 className="title"> Top Selling Products</h2>
                                          <p>Forget about struggling to do everything at once: taking care of the family, Running your business etc.</p>
                                       </div>
                                    </Col>
                                 </Row>
                                 {loading ===false ? 
                                 <TopSellingProduct products ={products}/>
                                 : 
                                 <div className= 'text-center'>
                                 <Loader type="Puff" color="#04d39f" height={100} width={100}/>
                                 </div>}
                              </Col>
                              </Row>
                           </Container>
                           <div className="container section-3">
                              <AboutBanner />
                           </div>
                        </div>
                     </article>
                  </main>
               </div>
            </div>
         </div>
        </div>
      )
}

export default HomePage;
