import React from 'react';
import { Button, Input, Form } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'store/actions'
import { toast } from 'react-toastify';
import History from '@history';

function EmailVerification(){
    const dispatch = useDispatch()
    const login = useSelector(({auth}) => {
        return auth.login.success ? true : false
    })
    if(login === true){
        History.push({
            pathname: '/'
        })
    }
    const onResendEmail = e => {
        e.preventDefault()
        let email = e.target.email ? e.target.email.value : null;
        if(email){
            dispatch(Actions.resendVerifyEmail(email))
        } else {
            toast.warn('Email not found')
        }
    }
    return(
        <div>
        <div className='reset-cont1st'  style={{display: 'flex', height: '100vh'}}>
            <div className="reset-container">
                <div className="reset-1st">
                    <div className="reset-main">
                        <div className="reset-div">
                            <div className="img-div">
                                <i class="fa fa-envelope-o" aria-hidden="true" style={{fontSize: '80px'}}></i>
                            </div>
                            <p className='reset-line'>Confirm your email address!</p>
                            <p>A confirmation e-mail has been sent.</p>
                            <p className='text-center mb-4'>Check your inbox and click on the "Confirm my email" link to confirm your email address</p>
                            <Form onSubmit={e => onResendEmail(e)} className='reset-form'>
                                <div className="reset-input-div">
                                    <div className="reset-input-2">
                                        <Input type="email" name="email" placeholder="Email *" className='reset-input' required/>
                                    </div>
                                </div>
                                <Button color='primary' type='submit' className='reset-btn'>Resend Link</Button>
                            </Form>
                            <div className='mt-4'><a href="/">Go back to login</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default EmailVerification;